import * as React from 'react'
import { t, TFunctionResult } from 'i18next'
import {
  Check as CheckIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid, Paper,
  Popover,
  PopoverOrigin,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import sprite_ from '../sass/sprite.module.sass'
import { PopupRequestCustomPackage } from './PopupRequestCustomPackage'
import { useSelector } from '../store'
import { usePopover } from '../hook/usePopover'
import { DeepWeb, FaceSearch, FindClone, Phone, Social, Source, WebInt } from '../store/searcher/reducer'
import {
  IFeature,
  IPackage,
  IPackageCustomized,
} from '../store/package/reducer'
import { colorLightGreen, colorPrimary } from '../mui'
import { useButtonStyles } from '../style/button'
import { concat } from 'lodash'
import { currencyFullName, currencySymbol, priceColor } from '../function/irbis-lite-config'

const phones: Phone[] = [
  'eyecon',
  'tc',
  'whatsapp',
  'skype',
  'telegram',
  'viber',
  'callapp',
  'getcontact',
  'hiya',
  'drupe',
  'imo',
  'tamtam',
  'botim',
  'luna',
  'taro',
]
const socials: Social[] = ['fb', 'twitter', 'ok', 'instagram', 'vk', 'linkedin']
const deepwebs: DeepWeb[] = ['email', 'phone', 'facebookid', 'telegramid', 'password', 'linkedinid', 'vkid', 'fullname', 'twitterid', 'instagramid']
const webint: WebInt[] = [  'quotaprofiler-lookup','quotaprofiler-crawler','quotaprofiler-cases','quotaprofiler-periodic','quotaprofiler-monitoring',
'quotawebint-lookup','quotawebint-crawler','quotawebint-cases','quotawebint-periodic','quotawebint-monitoring']
const findClone: FindClone[] = ['irbis_eye']
const faceSearch: FaceSearch[] = ['ok','vk','pluto','tiktok','sniper','sniper_2']

const minWidthFor3items = 1200
const minWidthFor5items = 1536

function getDuration(days: number): TFunctionResult {
  const months = [28, 29, 30, 31]
  if (months.includes(days)) {
    return t('package.duration.perMonth')
  } else {
    return `${t('package.duration.per')} ${days} ${t('package.duration.days')}`
  }
}

function getPhones(sources: Source[] | null) {
  return sources?.filter((el) => phones.includes(el as Phone)) || []
}

function getSocials(sources: Source[] | null) {
  return sources?.filter((el) => socials.includes(el as Social)) || []
}

function getDeepWeb(sources: Source[] | null) {
  return sources?.filter((el) => deepwebs.includes(el as DeepWeb)) || []
}

function getWebint(sources: Source[] | null) {
  return sources?.filter((el) => webint.includes(el as WebInt)) || []
}

function getFindClone(sources: Source[] | null) {
  return sources?.filter((el) => findClone.includes(el as FindClone)) || []
}

function getFaceSearch(sources: Source[] | null) {
  return sources?.filter((el) => faceSearch.includes(el as FaceSearch)) || []
}

interface InfoItemProps {
  feature: IFeature
  onInfoHover: (event: React.MouseEvent<HTMLElement>, text: string) => void
  setPopoverOpen: (status: boolean) => void
}

const countWordsDescriptionTitle = (text : string) : number => {
  let title : number
  const arr = text.split(" ")
  if (arr.length >= 0 && arr.length < 4) {
    title = 13
  } else {
    if (arr.length >= 4 && arr.length <11) {
    title = 10
    } else { 
         title = 8
    }
  }
  return title
}

const countWordsDescriptionSubTitle = (text : string) : number => {
  let title : number
  const arr = text.split(" ")
  if (arr.length >= 0 && arr.length < 4) {
    title = 14
  } else {
    if (arr.length >= 4 && arr.length <11) {
    title = 10
    } else { 
         title = 8
    }
  }
  return title
}

function InfoItem({ feature, onInfoHover, setPopoverOpen }: InfoItemProps) {
  if (feature.title === '') return null

  return (
    <Grid container mb={1}>
      <Grid item xs={2}>
        <CheckIcon color="primary" style={{ fontSize: 16 }} />
      </Grid>
      <Grid item xs={10}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="semiBold" fontSize={countWordsDescriptionTitle(feature.title)} sx={{wordBreak: 'break-word'}}>
            {feature.title}
          </Typography>
          {feature.info && (
            <Box
              onMouseEnter={(e) => onInfoHover(e, feature.info)}
              onMouseLeave={() => setPopoverOpen(false)}
            >
              <InfoIcon color="secondary" sx={{ fontSize: 13 }} />
            </Box>
          )}
        </Stack>
        <Stack>
        <Typography fontSize={countWordsDescriptionSubTitle(feature.subTitle)} sx={{wordBreak: 'break-word'}}>{feature.subTitle}</Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

interface Props {
  index: number
  data: IPackage | IPackageCustomized
  packagesQuantity: number
  setPurchaseOpen?: (data: IPackage) => void
}

export function PackageBadge({
  index,
  data,
  packagesQuantity,
  setPurchaseOpen,
}: Props) {
  const { userPackage, packageBalance } = useSelector((state) => state.settings)

  const { uid } = useSelector((state) => state.settings)

  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement>()

  const [popoverOpen, setPopoverOpen] = React.useState(false)

  const [popoverText, setPopoverText] = React.useState<React.ReactNode>()

  const [customOpen, setCustomOpen] = React.useState(false)

  const iframeUrl = React.useMemo(() => {
    const url = new URL(`https://store.payproglobal.com/checkout`)
    url.searchParams.set('exfo', '742')

    url.searchParams.set('products[1][id]', String(data.productId))

    url.searchParams.set('products[1][Name]', String(data.title))
    url.searchParams.set(`products[1][Price][${currencyFullName}][amount]`, String(data.price))
    url.searchParams.set(
      'currency',
      String(process.env.REACT_APP_PAYPRO_CURRENCY),
    )
    url.searchParams.set('x-uid', String(uid))
    url.searchParams.set('x-packageId', String(data.id))
    if (data.type == 'webinthub') {
      url.searchParams.set('x-packageType', 'webinthub')
    }
    if (data.type == 'webinthub_subpackage') {
      url.searchParams.set('x-packageType', 'webinthub_subpackage')
    }
    if (data.type == 'webinthub_upgrade') {
      url.searchParams.set('x-packageType', 'webinthub_upgrade')
    }
    if (process.env.REACT_APP_PAYPRO_TEST_MODE === 'true')
      url.searchParams.set('use-test-mode', 'true')
    if (process.env.REACT_APP_PAYPRO_TEST_MODE === 'true')
      url.searchParams.set(
        'secret-key',
        String(process.env.REACT_APP_PAYPRO_TEST_SECRET),
      )

    return url.href
  }, [data, uid])

  const isActive = React.useMemo(() => {
    return userPackage?.packageId === data.id
  }, [userPackage, data])
  const isOnRightSideOnPage = React.useMemo(() => {
    if (packagesQuantity > 5) {
      if (window.innerWidth < minWidthFor3items) {
        return (index + 1) % 2 === 0
      } else if (window.innerWidth < minWidthFor5items) {
        return (index + 1) % 3 === 0
      } else {
        return (index + 1) % 5 === 0
      }
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagesQuantity, index, window.innerWidth])
  const isCustom = data.type === 'custom'
  const isWebintHub = data.type === 'webinthub'
  const isSubWebintHub = data.type === 'webinthub_subpackage'
  const isWebintHubUpgrade = data.type == 'webinthub_upgrade'
  const isWebintHubAny = isWebintHub || isSubWebintHub || isWebintHubUpgrade

  const isDisabled =
    data.price < (userPackage?.price || 0) && packageBalance !== 0 && !isCustom && !isWebintHub && !isSubWebintHub
  const sourceApps = getPhones(data.sources)
  const sourceSocials = getSocials(data.sources)
  const sourceDeepWeb = getDeepWeb(data.sources)
  const sourceWebint = getWebint(data.sources)
  const sourceFindClone = getFindClone(data.sources)
  const sourceFaceSearch = getFaceSearch(data.sources)

  const allSources = [...new Set(concat(sourceApps, sourceSocials, sourceDeepWeb, sourceFindClone, sourceFaceSearch))]

  const { popoverClasses, popoverOptions } = usePopover(isOnRightSideOnPage)

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const [popOpen, setPopOpen] = React.useState<Boolean>(false)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleInfoHover(
    event: React.MouseEvent<HTMLElement>,
    text: React.ReactNode,
  ) {
    setPopoverText(text)
    setPopoverAnchor(event.currentTarget)
    setPopoverOpen(true)
  }

  function handleBuyClick() {
    if (isDisabled) return
    window.open(iframeUrl, '_blank')

    //setPurchaseOpen(data as IPackage)
  }

  if (data.isFree && packageBalance && packageBalance <= 0) {
    return null
  }

  const disableDisplay: string[] = ['quotaprofiler-periodic','quotaprofiler-monitoring','quotawebint-lookup','quotawebint-periodic']

  return <>
    {
      isCustom
        ? <Box
            sx={{
              height: '72px',
              width: '130px',
              borderRadius: '15px',
              background: '#4BDE97',
              cursor: 'pointer',
              position: 'relative',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
            }}
            onClick={() => setCustomOpen(true)}
          >
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{padding: '15px'}}>
              <Typography fontFamily={'Inter, sans serif'} fontSize={'20px'} fontWeight={700} color={'#FFF'}>{t('package.custom')}</Typography>
              <Typography fontFamily={'Inter, sans serif'} fontSize={'12px'} fontWeight={300} color={'#747474'}>{t('package.contactUs')}</Typography>
            </Stack>
          </Box>
        : <Box
          sx={{
            height: '72px',
            //width: '200px',
            borderRadius: '15px',
            background: '#F0F2FA',
            cursor: 'pointer',
            position: 'relative',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
          }}
          //onClick={() => setPopOpen(!popOpen)}
          onMouseOver={() => setPopOpen(true)}
          onMouseLeave={() => setPopOpen(false)}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-around'}
            sx={{
              padding: '5px 15px',
              position: 'relative',
            }}
          >
            <Stack direction={'row'} alignItems={'baseline'} justifyContent={'center'} sx={{ width: '100px', position: 'relative'}}>
              <Typography fontFamily={'Inter, sans serif'} fontSize={'20px'} fontWeight={700} color={isWebintHubAny ? '#03173D' : priceColor} marginRight={'5px'}>{currencySymbol}</Typography>
              <Typography fontFamily={'Inter, sans serif'} fontSize={'40px'} fontWeight={700} color={isWebintHubAny ? '#03173D' : priceColor}>{data.price}</Typography>
            </Stack>
            <Box sx={{width: '1px', height: '62px', background: 'rgba(116, 116, 116, 0.30)', margin: '0 10px'}}></Box>
            <Stack direction={'column'} justifyContent={'flex-start'} sx={{marginBottom: '5px', width: '100px', paddingLeft: '10px'}}>
              <Tooltip title={data.title}><Typography fontFamily={'Inter, sans serif'} fontSize={'20px'} fontWeight={300} color={'#747474'} noWrap={true} sx={{overflow: 'hidden'}}>{data.title}</Typography></Tooltip>
              <Tooltip title={data.subTitle.context}><Typography fontFamily={'Inter, sans serif'} fontSize={'12px'} fontWeight={300} color={'#747474'} noWrap={true} lineHeight={1}>{data.subTitle.context}</Typography></Tooltip>
            </Stack>
            {
              isActive && <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderRadius: '20px',
                  background: priceColor,
                  padding: '0px 10px',
                  height: '18px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 11,
                }}
              >
                <Typography fontSize={'10px'} fontWeight={500} color={'#FFF'}>Active</Typography>
              </Box>
            }
            {/*{
              data.annualPrice > 0 && <Box
                sx={{
                  position: 'absolute',
                  top: '20px',
                  left: '0px',
                  //backgroundColor: '#FFF',
                  borderRadius: '20px',
                  height: '18px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 11,
                  opacity: 0.6,
                  paddingLeft: '5px',
                }}>
                <Tooltip title={'Annual bonus'}>
                  <Typography fontSize={'12px'} fontWeight={700} color={'#3898D3'}>€10</Typography>
                </Tooltip>
              </Box>
            }*/}
          </Stack>
          <Stack
            display={popOpen ? 'block' : 'none'}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              backgroundColor: '#F0F2FA;',
              marginTop: '57px',
              borderBottomRightRadius: '15px',
              borderBottomLeftRadius: '15px',
              padding: '20px',
              zIndex: 10,
              boxShadow: '0 10px 10px 0px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Stack padding={'10px 0'}>
              <Typography variant="semiBold" fontSize={16} align="center">
                {data.title}
              </Typography>
              <Typography
                fontSize={12}
                align="center"
                //sx={{ marginTop: '10px'}}
              >
                {data.subTitle.context}
              </Typography>
            </Stack>


            <Box width="100%" maxHeight={1} mt={1} mb={2}>
              <Divider color="primary" />
            </Box>

            <Stack
              justifyContent="space-around"
              alignItems="center"
              //height={120}
              sx={{margin: '20px 0'}}
            >
              {allSources.length > 0 && (
                <Grid container justifyContent="center" spacing={1}>
                  {allSources.map((el) => (
                    <Grid item key={el + data.id}>
                      <div className={sprite_['social__small_' + el]} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {sourceWebint.length > 0 && (
                <Grid container justifyContent="center" spacing={1}>
                  {sourceWebint.map((el) => (
                    (!disableDisplay.includes(el) &&
                      <Grid item key={el + data.id}>
                        <div className={sprite_['social__small_' + el]} />
                      </Grid>
                    )
                  ))}
                </Grid>
              )}
            </Stack>
            <Stack
              width="100%"
              flexGrow={1}
              justifyContent={'none'}
            >
              <InfoItem
                feature={data.feature1}
                onInfoHover={handleInfoHover}
                setPopoverOpen={setPopoverOpen}
              />
              <InfoItem
                feature={data.feature2}
                onInfoHover={handleInfoHover}
                setPopoverOpen={setPopoverOpen}
              />
              <InfoItem
                feature={data.feature3}
                onInfoHover={handleInfoHover}
                setPopoverOpen={setPopoverOpen}
              />
              {isCustom && (
                <InfoItem
                  feature={(data as IPackageCustomized).feature4}
                  onInfoHover={handleInfoHover}
                  setPopoverOpen={setPopoverOpen}
                />
              )}
            </Stack>

            {!data.isFree && (
              <Box width="100%" sx={{padding: '10px 0'}}>
                {isCustom ? (
                  <Button
                    size="large"
                    fullWidth
                    onClick={() => setCustomOpen(true)}
                  >
                    {data.button}
                  </Button>
                ) : isDisabled ? (
                  <Tooltip title="You can't pay for cheaper package">
                    <Box>
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={isDisabled}
                        onClick={handleBuyClick}
                      >
                        {data.button}
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={isDisabled}
                    onClick={handleBuyClick}
                  >
                    {data.button}
                  </Button>
                )}
              </Box>
            )}

          </Stack>
        </Box>
    }
    {
      customOpen && <PopupRequestCustomPackage onClose={() => setCustomOpen(false)} />
    }
  </>
}
