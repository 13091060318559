import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider, Fade, Paper, Stack, Typography, Unstable_TrapFocus,
} from '@mui/material'
import { nameCaption } from '../function/irbis-lite-config'

interface IPopupMobile {
  onClose: () => void
}

export const PopupMobile = ({ onClose }: IPopupMobile) => {
  const { t } = useTranslation()

  return (
    <>
      <Stack sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: '.4',
        zIndex: 1,
        background: 'black',
      }} />

      <Unstable_TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={true} in={true}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'absolute',
              //bottom: 0,
              top: '70px',
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 1,
              margin: '15px!important',
              zIndex: 1,
              borderRadius: '20px',
              boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              gap={2}
            >
              <Box
                sx={{
                  flexShrink: 1,
                  alignSelf: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <Typography variant={'title'} sx={{padding: '20px 0', display: 'block'}}>🌟 Hey there!</Typography>
                <Typography variant={'subtitle1'} display={'block'} sx={{fontWeight: 'bold', padding: '20px 0'}}>Just a heads-up: </Typography>
                <Typography fontSize={16} sx={{padding: '10px', display: 'block', backgroundColor: 'cornsilk'}}>You're currently using {nameCaption} in <b>mobile mode 📱</b>, which has some limitations.</Typography>
                <Typography fontSize={16} sx={{padding: '10px', display: 'block'}}>For the full, awesome experience, we recommend switching to a <b>desktop 🖥️</b>. </Typography>

                <Typography variant="subtitle2" sx={{display: 'block', padding: '20px 0'}}>
                  Happy searching! 🚀
                </Typography>
              </Box>
              <Stack
                gap={2}
                direction={{
                  xs: 'row-reverse',
                  sm: 'row',
                }}
                sx={{
                  flexShrink: 0,
                  alignSelf: { xs: 'flex-end', sm: 'center' },
                }}
              >
                <Button size="small" onClick={onClose} variant="contained">
                  Got it
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </Unstable_TrapFocus>
    </>
  )
}
