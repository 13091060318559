import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import { LocalStorage } from '../../enum/storage'
import './SwaggerSection.css'
import { ILookup, Type } from '../../store/searcher/reducer'
import { Table, TableHead, TableRow, TableCell, TableBody, Stack, Box } from '@mui/material'
import { useTableStyles } from '../datatable/hook/useStyles'
import sprite_ from '../../sass/sprite.module.sass'
import { getLookups } from '../../store/searcher/selector'
import { useSelector } from '../../store'
import { useAuthEffect } from '../../hook/useAuthEffect'
import { fetchLookup } from '../../store/searcher/actionCreator'


/**
 * continers that can be wrapped 
 * can be viewed on https://github.com/swagger-api/swagger-ui/blob/master/src/core
 * */

const lookupPathMap = new Map<string,Type>([
  ["/api/developer/phone", "phone"],
  ["/api/developer/name", "name"],
  ["/api/developer/sn_api_names", "name_api"],
  ["/api/developer/post", "post"],
  ["/api/developer/deepweb", "deepweb"],
  ["/api/developer/combined_phone", "combined_phone"],
  ["/api/developer/combined_email", "combined_email"],
  ["/api/developer/combined_name", "combined_name"],
  ["/api/developer/combined_id", "combined_id"],
  ["/api/developer/face_search", "face_search"],
  ["/api/developer/fb_collection_search", "web_data_search"],
  ["/api/developer/fb_collection_person", "web_data_search"],
  ["/api/developer/fb_collection_person_wall_keyword", "fb_person_wall_keyword"],
  ["/api/developer/fb_collection_group_person", "web_data_search"],
  ["/api/developer/fb_collection_page_person", "web_data_search"],
  ["/api/developer/sentiment_analysys", "sentiment_analysys"],
  ["/api/developer/irbis_eye", "irbis_eye"],
  ["/api/developer/", "demo"],
  ["/api/developer/kyc", "kyc"],
  ["/api/developer/web_collection", "web_collection"],
  ["/api/developer/ip_geolocation", "ip_geo"],
  ["/api/developer/sniper_api", "photo_searcher"],
  ["/api/developer/ip_phone_verification", "ipgeo_number"],
  ["/api/developer/psycho_profile", "psycho_profile"],
  ["/api/developer/psycho_profile_summary", "psycho_profile_summary"],
  ["/api/developer/x", "api_v2_x"],
  ["/api/developer/instagram","api_v2_instagram"],
  ["/api/developer/linkedin","api_v2_linkedin"],
  ["/api/developer/social_profiler","sn_api_profiler"],
  ["/api/developer/webint_profiler","sn_api_group_profiler"],
  ["/api/developer/webint_keywords","sn_api_group_profiler"],
  ["/api/developer/sn_api_profiler_fb_friends","sn_api_profiler_fb_friends"],
  ["/api/developer/sn_api_profiler_fb_posts","sn_api_profiler_fb_posts"],
  ["/api/developer/sn_api_profiler_insta_friends","sn_api_profiler_insta_friends"],
  ["/api/developer/sn_api_profiler_insta_posts","sn_api_profiler_insta_posts"],
  ["/api/developer/phone_lookup_verification","lookup_phone_verification"],
  ["/api/developer/lookup_phone_name_verification","lookup_phone_name_verification"],
  ["/api/developer/real_phone","real_phone"],
  ["/api/developer/facebook_v3","irbis_facebook"],
  ["/api/developer/instagram_v3","irbis_instagram"],
  ["/api/developer/x_v3","crolo_x"],
  ["/api/developer/vk_v3","crolo_vk"],
  ["/api/developer/tiktok_v3","irbis_tiktok"],
  ["/api/developer/bluesky_v3","bluesky_v3"],
  ["/api/developer/ok_v3","crolo_ok"],
  ["/api/developer/irbis_background_checks","irbis_criminal"],
  ["/api/developer/compliance_screening/ssn_trace","ssn_trace"],
  ["/api/developer/compliance_screening/nat_crim_check","nat_crim_check"],
  ["/api/developer/compliance_screening/pep","pep"],
  ["/api/developer/compliance_screening/court_records","court_records"],
])

// const ignorePaths: string[] = [
//   "api/developer/delete",
//   "api/request-monitor/api-usage",
//   "api/request-monitor/api-usage/{id}",
// ]

const getLookupsTable = (lookups: ILookup[], tableClasses: any) => {
  
  return (
    <Table
    classes={tableClasses}
    className={tableClasses.developerTypes}
    size="small"
  >
    <TableHead>
      <TableRow>
        <TableCell classes={tableClasses} className={'lookup_id'}>Lookup ID</TableCell>
        <TableCell classes={tableClasses} className={'lookup_type'}>Lookup type</TableCell>
        <TableCell classes={tableClasses}>Description</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {lookups && lookups.sort((a, b) => a.id < b.id ? -1 : 1).map(({ id, title, subTitle }, idx) => (
        <TableRow
          key={idx}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell classes={tableClasses} className={'lookup_id'} scope="row">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box className={sprite_.common__search} />
              <Box>{id}</Box>
            </Stack>
          </TableCell>
          <TableCell classes={tableClasses} className={'lookup_type'}>{title}</TableCell>
          <TableCell classes={tableClasses}>{subTitle}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  )
}

interface IProps {
  getComponent?: any
}
// Create the layout component
class AugmentingLayout extends React.Component<IProps> {
  render() {
    const {
      getComponent,
    } = this.props

    const BaseLayout = getComponent("BaseLayout", true)
    return (
      <div>
        <BaseLayout />
      </div>
    )
  }
}
// Create the plugin that provides our layout component
const AugmentingLayoutPlugin = (system: any, lookups: ILookup[] | null, tableClasses: any) => {
  return {
    components: {
      AugmentingLayout: AugmentingLayout,
      info: () => null,
      auth: () => null,
      schemeContainer: function() { return null },
    },
    wrapComponents: {
      AuthContainer: () => () => null,
      authorizeBtn: () => () => null,
      schemas: () => () => null,
      auth: () => () => null,
      info: (Original: any, system: any) => (props: any) => {
        return system.React.createElement(Original, {
          ...props,
          info: props.info.set("description", "123"),
        })
      },
      scheme: () => () => null,
      operation: (Original: any, system: any) => (props: any) => {
        const {operation} = props
        if (
          lookupPathMap.has(operation.get("path")) &&
          operation.get("method") === "post" && 
          operation.size // i.e., resolved Operation has been provided
        ){
          
          if (lookups){
            const filteredLookups:ILookup[] = lookups.filter((lookup) => lookup.type == lookupPathMap.get(operation.get("path")))
            if (filteredLookups.length > 0)
              return <div>
                {getLookupsTable(filteredLookups, tableClasses)}
                <Original {...props}/>
              </div>
            else return <></>
          }
        }
        return system.React.createElement(Original, props)
      },
    },
    rootInjects: {

    },
  }
}

interface SwaggerProps {
  lookups: ILookup[] | null
}

function SwaggerSection(props: SwaggerProps) {
  const tableClasses = useTableStyles()
  const token = localStorage.getItem(LocalStorage.TOKEN)
  const requestInterceptor = (req: any) => (
    {
      ...req,
      headers: {
        //Authorization: `Bearer ${token}`,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )

  return (
    <>
      <SwaggerUI
        url={`${process.env.REACT_APP_API_BASE_URL}/swagger-client-json`}
        requestInterceptor={requestInterceptor}
        plugins={[AugmentingLayoutPlugin(null, props.lookups, tableClasses)]}
        layout={'AugmentingLayout'}
        onComplete={(system: any) => {
          const jsonSpec = system.getState().toJSON().spec.json
          jsonSpec.paths = Object.fromEntries(Object.entries(jsonSpec.paths),
          )
          jsonSpec.tags = []
          return system.specActions.updateJsonSpec(jsonSpec)
        }}
        defaultModelsExpandDepth={-1}
      />
    </>
  )
}

export default SwaggerSection