import { styled } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import { isIrbisLite } from '../function/irbis-lite-config'

export const OsintBlueButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'uppercase',
  fontSize: 20,
  padding: '6px 12px',
  backgroundColor: '#2E97D5',
  borderRadius: '50px',
  height: '53px',
  minWidth: '136px',
  '&:hover': {
    backgroundColor: '#A9D8F4',
  },
  '&:active': {
    backgroundColor: '#A9D8F4',
  },
})

export const  OsintAPIBlueButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'uppercase',
  fontSize: 15,
  marginTop: '20px',
  padding: '0px 15px',
  backgroundColor: isIrbisLite ? '#3c64c3' : '#A9D8F4',
  borderRadius: '50px',
  height: '50px',
  minWidth: '120px',
  '&:hover': {
    backgroundColor: '#A9D8F4',
  },
  '&:active': {
    backgroundColor: '#A9D8F4',
  },
})

export const OsintGreenButton = styled(Button)({
  boxShadow: 'none',
  fontFamily: 'Roboto, sans-serif',
  fontSize: 32,
  padding: '4px 15px',
  backgroundColor: '#2ED571',
  borderRadius: '10px',
  height: '46px',
  width: '142px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '38px',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#6CE09B',
  },
  '&:active': {
    backgroundColor: '#6CE09B',
  },
})

export const OsintGreenSmallButton = styled(Button)({
  boxShadow: 'none',
  fontFamily: 'Roboto, sans-serif',
  fontSize: 12,
  padding: '6px 15px',
  backgroundColor: 'transparent',
  border: '1px solid #2ED571',
  borderRadius: '4px',
  height: '32px',
  width: '78px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '24px',
  color: '#2ED571',
  '&:hover': {
    backgroundColor: '#6CE09B',
    color: '#FFFFFF',
  },
  '&:active': {
    backgroundColor: '#6CE09B',
    color: '#FFFFFF',
  },
})

export const OsintVideoPlayButton = styled(Button)({
  boxShadow: 'none',
  padding: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: '125px',
  width: '125x',
  '&:hover': {
    opacity: '0.7',
  },
  '&:active': {
    opacity: '0.7',
  },
})

export const OsintInvestigationButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'uppercase',
  fontSize: 35,
  padding: '6px 12px',
  backgroundColor: '#2E97D5',
  border: '2px solid #FFFFFF',
  borderRadius: '50px',
  height: '71px',
  minWidth: '488px',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.6);',
  },
  '&:active': {
    backgroundColor: 'rgba(255, 255, 255, 0.6);',
  },
})