import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography, useTheme,
} from '@mui/material'
import { PrivatePage } from '../component/PagePrivate'
import { getLocaleJson } from '../i18n'
import { useDispatch, useSelector } from '../store'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import developer_ from '../sass/developer.module.sass'
import sprite_ from '../sass/sprite.module.sass'
import staticData from '../json/developerMode.json'
import { deleteApikey, fetchApikey, fetchDeveloperVideo } from '../store/apikey/actionCreator'
import { getUrlFromBlob } from '../function/image'
import { useAuthEffect } from '../hook/useAuthEffect'
import "swagger-ui-react/swagger-ui.css"
import SwaggerSection from '../component/developer/SwaggerSection'
import { getLookups } from '../store/searcher/selector'
import { fetchLookup } from '../store/searcher/actionCreator'
import { Apikey } from '../store/apikey/reducer'
import {
  Edit as EditIcon,
  DeleteOutline as DeleteIcon,
  FileCopy as CopyIcon,
} from '@mui/icons-material'
import { TableBodyData, TableBodyRow } from '../store/common/reducer'
import { format } from 'date-fns'
import { Body, Header, Navigation } from '../component/datatable/component'
import { PopupCRUD } from '../container/apikey/PopupCRUD'
import { PopupAsk } from '../component/PopupAsk'
import { OsintAPIBlueButton } from '../style/osint'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'
import { apiDocsUrl, isIrbisLite } from '../function/irbis-lite-config'

const videoWidth = 460
const videoHeight = 275

const servers = [
  { id: 1, label: process.env.REACT_APP_API_BASE_URL?.replace('/api', '') },
]
const videoStaticPath = '/static/clip/34567.mp4'


export function DeveloperPage() {
  const { t } = useTranslation()
  const tableClasses = useTableStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  
  const user = useSelector((state) => state.settings)
  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/developer")
    }
  }, [])

  const { developerVideo } = useSelector((state) => state.apikey)
  const lookups = useSelector(getLookups)

  const { apikey } = useSelector((state) => state.apikey)

  useAuthEffect(() => {
    dispatch(fetchDeveloperVideo)
    dispatch(fetchLookup)
    dispatch(fetchApikey)
  })

  const [openCreate, setOpenCreate] = React.useState(false)
  const [editData, setEditData] = React.useState<Apikey | null>(null)
  const [deleteId, setDeleteId] = React.useState<number>()
  const [sureDeleteOpen, setSureDeleteOpen] = React.useState(false)

  const tableHeaderData = [
    { key: 'key', text: t('apiKey.table.key') },
    { key: 'allowedDomains', text: t('apiKey.table.allowedDomains'), className: "wideVisible" },
    { key: 'created', text: t('apiKey.table.created'), className: "wideVisible" },
    { key: 'action', text: t('apiKey.table.action'), style: { width: 80 } },
  ]

  const videoUrl = React.useMemo(() => {
    if (developerVideo === undefined) return
    return getUrlFromBlob(developerVideo, 'video/mp4')
  }, [developerVideo])

  const TableBodyRender = (row: Apikey) => (
    <TableRow key={row.id}>
      <TableCell>
        <Typography>{row.key}</Typography>
        <Tooltip title={<span>{t('common.copy')}</span>}>
          <IconButton onClick={(e) => handleCopyKey(e, row.key)}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell className="wideVisible">
        {row.domains.map((el, idx) => (idx !== 0 ? ' • ' + el : el))}
      </TableCell>
      <TableCell className="wideVisible">
        {row.createdTime ? format(new Date(row.createdTime), 'dd.MM.yyyy') : ''}
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title={<span>{t('common.edit')}</span>}>
            <IconButton onClick={() => setEditData(row)} size="small">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={<span>{t('common.delete')}</span>}>
            <IconButton onClick={() => handleAskDelete(row.id)} size="small">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  )

  const tableApiKeyBodyData: TableBodyData | undefined = React.useMemo(() => {
    if (apikey === null) return undefined

    return {
      list: [apikey] as TableBodyRow[],
      count: 1,
    }
  }, [apikey])

  function handleAskDelete(id: number) {
    setDeleteId(id)
    setSureDeleteOpen(true)
  }

  function handleCopyKey(e: React.MouseEvent<HTMLButtonElement>, key: string) {
    e.stopPropagation()
    navigator.clipboard.writeText(key)
  }

  function handleDelete() {
    setSureDeleteOpen(false)
  }


  return (
    <PrivatePage>
      {openCreate && (
        <PopupCRUD mode="create" onClose={() => setOpenCreate(false)} />
      )}

      {editData && (
        <PopupCRUD
          mode="edit"
          data={editData}
          onClose={() => setEditData(null)}
        />
      )}

      {sureDeleteOpen && deleteId && (
        <PopupAsk
          onYes={() => dispatch(deleteApikey(deleteId))}
          onClose={handleDelete}
        />
      )}

      <Stack spacing={2} width="100%" direction="row" >
        <TableContainer classes={tableClasses} className={tableClasses.card} style={{ width: "85%", minWidth: "230px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Navigation title={t('apiKey.title')} />
            {apikey === null && (
              <OsintAPIBlueButton
                style={{ margin: "20px 20px" }}
                variant="contained"
                color="primary"
                onClick={() => setOpenCreate(true)}
              >
                {t('apiKey.createAPIkey')}
              </OsintAPIBlueButton>
            )}
          </Stack>

          <Table>
            <Header headerData={tableHeaderData} />
            {apikey && (
              <Body
                bodyData={tableApiKeyBodyData}
                renderBody={TableBodyRender}
                cellCount={4}
              />
            )}
          </Table>

        </TableContainer>

        {/* {videoUrl ? (
          videoUrl !== '' ? (
            <video
              src={videoUrl}
              width={videoWidth}
              height={videoHeight}
              controls
            />
          ) : (
            <video
              src={videoStaticPath}
              width={videoWidth}
              height={videoHeight}
              controls
            />
          )
        ) : (
          <Skeleton
            variant="rectangular"
            width={videoWidth}
            height={videoHeight}
          />
        )} */}

        {!isIrbisLite && (
          videoUrl ? (
            videoUrl !== "" ? (
              <video src={videoUrl} width={videoWidth} height={videoHeight} controls />
            ) : (
              <video
                src={videoStaticPath}
                width={videoWidth}
                height={videoHeight}
                controls
              />
            )
          ) : (
            <Skeleton variant="rectangular" width={videoWidth} height={videoHeight} />
          )
        )}

        <Card sx={{
          position: 'relative',
          height: "100%",
          width: "45%",
        }}>
          <CardContent sx={{ zIndex: 5, height: '100%' }}>

            <Typography variant="text" color={'black'}
              fontSize={14} sx={{ lineHeight: '10px', paddingTop: '1px' }}>
              API Documentation: Explore detailed documentation, including endpoints, request formats, response structures, and authentication methods. Get started quickly and efficiently with our user-friendly API documentation section.
            </Typography>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <OsintAPIBlueButton variant="contained" onClick={(e) => {
                window.open(apiDocsUrl, '_blank')
              }}>API docs</OsintAPIBlueButton>
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <Card sx={{
        [theme.breakpoints.only("xs")]: {
          '& *': {
            fontSize: '10px',
          },
        },
      }}>
        <CardHeader title={t('developer.apiLookupTypes')} />
        <CardContent className={developer_.swagger}>
          {lookups && <SwaggerSection lookups={lookups}/>}
        </CardContent>
      </Card>

    </PrivatePage>
  )
}
