import { SearchResult, Type } from '../../store/searcher/reducer'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box, Button, IconButton,
  LinearProgress,
  Stack,
  TableCell, Tooltip,
  Typography, useTheme,
} from '@mui/material'
import { formatDate, getNow } from '../../function/date'
import { t } from 'i18next'
import sprite_ from '../../sass/sprite.module.sass'
import * as React from 'react'
import { useInProgressStyles } from '../../style/progress'
import { useTransparentAccordionStyles } from '../../style/accordion'
import { toggleViewResult } from '../../store/searcher/actionCreator'
import { useDispatch } from '../../store'
import { cleanJSON, generateXLSXData, getNameVerificationCriteria } from '../../function/string'
import * as XLSX from 'xlsx'
import { HeaderData } from '../datatable/types'
import ProgressCircle from '../ProgressCircle'

function getSearchType(type: Type) {
  switch (type) {
    case 'phone':
      return t('searcher.type.phone')
    case 'name':
      return t('searcher.type.name')
    case 'name_api':
        return t('searcher.type.name_api')
    case 'post':
      return t('searcher.type.post')
    case 'deepweb':
      return t('searcher.type.deepweb')
    case 'combined_phone':
      return t('searcher.type.combined_phone')
    case 'combined_email':
      return t('searcher.type.combined_email')
    case 'combined_name':
      return t('searcher.type.combined_name')
    case 'combined_id':
      return t('searcher.type.combined_id')
    case 'face_search':
      return t('searcher.type.face_search')
    case 'irbis_eye':
      return t('searcher.type.irbis_eye')
    case 'sentiment_analysys':
      return t('searcher.type.sentiment_analysys')
    case 'web_data_search':
      return t('searcher.type.web_data_search')
    case 'fb_person_wall_keyword':
      return t('searcher.type.web_data_search')
    case 'kyc':
      return t('searcher.type.kyc')
    case 'web_collection':
      return t('searcher.type.web_collection')
    case 'ip_geo':
      return t('searcher.type.ip_geo')
    case 'name_by_country':
      return t('searcher.type.name_by_country')
    case 'photo_searcher':
        return t('searcher.type.sniper_api')
    case 'phone_list' :
      return t('searcher.type.phone_list')
    case 'email_list' :
      return t('searcher.type.email_list')
    case 'ipgeo_number' : 
      return t('searcher.type.ipgeo_number')
    case 'psycho_profile' : 
      return t('searcher.type.psycho_profile')
    case 'sn_api_profiler' : 
      return t('searcher.type.sn_api_profiler')
    case 'sn_api_group_profiler' : 
      return t('searcher.type.sn_api_group_profiler')
    case 'api_v2_x' : 
      return t('searcher.type.api_v2_x')
    case 'api_v2_instagram': 
      return t('searcher.type.api_v2_instagram')
    case 'api_v2_linkedin': 
      return t('searcher.type.api_v2_linkedin')
    case 'lookup_phone_name_verification': 
      return t('searcher.type.lookup_phone_name_verification')
    case 'real_phone':
      return t('searcher.type.real_phone')
    case 'sn_api_profiler_fb_friends' : 
      return t('searcher.type.sn_api_profiler_fb_friends')
    case 'sn_api_profiler_fb_posts' : 
      return t('searcher.type.sn_api_profiler_fb_posts')
    case 'sn_api_profiler_insta_friends' : 
      return t('searcher.type.sn_api_profiler_insta_friends')
    case 'sn_api_profiler_insta_posts' : 
      return t('searcher.type.sn_api_profiler_insta_posts')
    case 'irbis_facebook' :
      return t('searcher.type.irbis_facebook')
    case 'irbis_instagram' :
        return t('searcher.type.irbis_instagram')
    case 'crolo_x' : 
      return t('searcher.type.crolo_x')
    case 'crolo_vk' : 
      return t('searcher.type.crolo_vk')
    case 'crolo_ok' : 
      return t('searcher.type.crolo_ok')
    case 'irbis_criminal' : 
      return t('searcher.type.irbis_criminal')
    case 'ssn_trace' : 
      return t('searcher.type.ssn_trace')
    case 'nat_crim_check' : 
      return t('searcher.type.nat_crim_check')
    case 'pep' : 
      return t('searcher.type.pep')
    case 'irbis_tiktok' : 
      return t('searcher.type.irbis_tiktok')
    case 'court_records' :
      return t('searcher.type.court_records')
    case 'bluesky_v3' : 
      return t('searcher.type.bluesky_v3')

  }
}

type Props = {
  row: SearchResult
  setDelete: any
}

export const RenderTableBody = ({row, setDelete}: Props) => {
  //hooks
  //const dispatch = useDispatch()
  //const progressInClasses = useInProgressStyles()
  //const transparentAccordion = useTransparentAccordionStyles()
  const theme = useTheme()
  //handlers
  // function handleSourceClick(e: React.MouseEvent, row: SearchResult) {
  //   if (row.status === 'progress') e.stopPropagation()
  //   else dispatch(toggleViewResult(row))
  // }
  function handleDownload(e: React.MouseEvent, row: SearchResult) {
    e.stopPropagation()

    const json = cleanJSON(row)//JSON.stringify(row)
    const blob = new Blob([json], { type: 'application/json' })
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    const sources = row.sources.map((el) => el.name)
    link.href = href
    // basically checking if the criteria is too long for a filename
    const filePrefix = row.criteria.length > 1000 || row.criteria.includes("data:image/") ? "" : row.criteria + "_"
    link.download = `${filePrefix}${
      sources.join("_") || ''
    }_${getNow()}.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function handleDeletePopup(e: React.MouseEvent, row: SearchResult) {
    e.stopPropagation()
    setDelete(row)
  }

  function handleDownloadXlsx(e: React.MouseEvent, row: SearchResult) {
    e.stopPropagation()
    const json : any = cleanJSON(row)
    const data = JSON.parse(json)

    const lookupData = generateXLSXData(data)

    const workbook = XLSX.utils.book_new()
    try {
    const worksheet = XLSX.utils.json_to_sheet(lookupData)
    
    const objectMaxLength : any[] = []
  
    lookupData.forEach( (row : any) => {
      Object.keys(row).forEach((key, index) => {
        const columnLength = row[key] ? row[key].toString().length : 10
        objectMaxLength[index] = Math.max(objectMaxLength[index] || 10, columnLength)
      })
    })
  
    worksheet['!cols'] = objectMaxLength.map(width => {
      return { width: width + 3 }
    })

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    } catch (e) {

    }
    XLSX.writeFile(workbook, "DataSheet.xlsx")
  }

  return (
    <>
      { (row.type === 'face_search' || row.type === 'irbis_eye' || row.type === 'photo_searcher' ? (
        <TableCell>
          <Avatar alt="Remy Sharp" src={row.criteria} />
        </TableCell>
      ) : (
        row.type === 'sentiment_analysys' && row.criteria.length > 25 ?
          <TableCell>{row.criteria.substr(0,25) + "..."}</TableCell> :
          ( row.type === 'lookup_phone_name_verification' ?  
          <TableCell>{getNameVerificationCriteria(row.criteria, 25)}</TableCell> :
          <TableCell style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px'}}>{row.criteria}</TableCell>
          )
      ))
      }
      <TableCell className="wideVisible"  sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Stack sx={{
          borderRadius: '4px',
          background: '#3898D3',
          padding: '3px 10px',
          width: 'fit-content',
          color: '#FFF',
          fontSize: '12px',
          fontWeight: 600,
          whiteSpace: 'nowrap',
        }}
               direction={'row'}
               alignItems={'center'}
               justifyContent={'center'}
        >
          { row.requestCaption ? row.requestCaption : getSearchType(row.type)}
        </Stack>
      </TableCell>
      <TableCell className="wideVisible">{formatDate(row.createdTime, 'dd/MM/yyyy HH:mm')}</TableCell>
      <TableCell>
        {row.status === 'progress' && row.percentage ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {/* <Typography noWrap>{t('searcher.status.progress')}</Typography> */}
            <Box sx={{
              [theme.breakpoints.only("xs")]: {
                display: 'none',
              },
              }}
            >
              {/* <LinearProgress
                variant="determinate"
                color="success"
                value={Number(row.percentage)}
              /> */}
              <ProgressCircle results = {row}/>
            </Box>
          </Stack>
        ) : row.status === 'finished' ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {/* <Typography noWrap>{t('searcher.status.finished')}</Typography> */}
            <Box sx={{
                [theme.breakpoints.only("xs")]: {
                  display: 'none',
                },
              }}
            >
              {/* <LinearProgress
                variant="determinate"
                color="success"
                value={100}
              /> */}
              <ProgressCircle results = {row}/>

            </Box>
          </Stack>
        ) : row.status === 'failed' ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {/* <Typography noWrap>Failed</Typography> */}
            <Box sx={{
              [theme.breakpoints.only("xs")]: {
                display: 'none',
              },
            }}>
              {/* <LinearProgress variant="determinate" value={0} /> */}
              <ProgressCircle results = {row}/>
            </Box>
          </Stack>
        ) : row.status === 'empty' ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography noWrap>{t('searcher.status.empty')}</Typography>
              <Box sx={{
                [theme.breakpoints.only("xs")]: {
                  display: 'none',
                },
              }}>
                {/* <LinearProgress
                  variant="determinate"
                  color="success"
                  value={100}
                /> */}
                <ProgressCircle results = {row}/>
              </Box>
            </Stack>
          ) :
          (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              {/* <Typography noWrap>{t('searcher.status.progress')}</Typography> */}
              <Box sx={{
                [theme.breakpoints.only("xs")]: {
                  display: 'none',
                },
              }}>
                {/* <LinearProgress
                  classes={progressInClasses}
                  variant="determinate"
                  value={100}
                /> */}
                <ProgressCircle results = {row}/>
              </Box>
            </Stack>
          )}
      </TableCell>
      {/* <TableCell className="wideVisible">
      { 
              row.type === 'phone_list' ?
              <Tooltip title={<span>{t('common.downloadXLSX')}</span>}>
              <Button variant="contained" color="primary" onClick={(e) => handleDownloadXlsx(e, row)} disabled={row.status !== 'finished'}>
                Download *.xlsx file
              </Button>
            </Tooltip>
      :
      ( (row.type !== 'combined_phone' && row.type !== 'real_phone' && row.type !== 'phone' && row.type !== 'verify_name') &&
        <Accordion
          classes={transparentAccordion}
          onClick={(e) => handleSourceClick(e, row)}
          expanded={
            row.status === 'progress' && row.percentage ? undefined : false
          }
          disabled={row.status === 'finished'}
        >
          <AccordionSummary>
            <Stack direction="row" spacing={1}>
            {row.sources.map((el, idx) => (
                <Box
                  key={idx}
                  //className={sprite_['social__small_' + el.name]}
                  className={(el.name === 'fb_api' || el.name === 'instagram_api' 
                    || el.name === 'x_api' || el.name === 'linkedin_api' || el.name === 'vk_api' ) 
                                    ? sprite_['social__small_' + el.name.replace("_api","")] : sprite_['social__small_' + el.name]}
                />
              ))}
              {
                (row?.type === 'web_data_search' || row?.type === 'fb_person_wall_keyword') && <Box
                  key={'fb'}
                  className={sprite_['social__small_fb']}
                />
              }
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
          { 
             row.sources.map((el, idx) => (
              <Stack
                key={idx}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Box className={sprite_['social__small_' + el.name]} />
                <Box width="60%">
                  <LinearProgress
                    variant="determinate"
                    color="success"
                    value={Number(el.percentage) || 0}
                  />
                </Box>
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      )
      }
      </TableCell> */}
      <TableCell sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Stack direction="row" spacing={1}>
          {/* <Tooltip title={<span>{t('common.download')}</span>} sx={{
            [theme.breakpoints.only("xs")]: {
              display: 'none',
            },
          }}>
            <IconButton onClick={(e) => handleDownload(e, row)} size="small">
              <Box className={sprite_.common__downloadNew} />
            </IconButton>
          </Tooltip> */}

          <Tooltip title={<span>{t('common.delete')}</span>}>
            <IconButton
              onClick={(e) => handleDeletePopup(e, row)}
              size="small"
            >
              <Box className={sprite_.common__deleteNew} />
            </IconButton>
          </Tooltip>

          { ( row.type === 'phone_list' ) &&
              <Tooltip title={<span>{t('common.downloadXLSX')}</span>}>
              <Button variant="contained" color="primary" onClick={(e) => handleDownloadXlsx(e, row)} disabled={row.status !== 'finished'}>
                *.xlsx
              </Button>
            </Tooltip> }
        </Stack>
      </TableCell>

    </>
  )
}