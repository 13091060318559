import { ILookup, SearchResult, Source } from './reducer'
import { ApiDataSummarizing, VerifiedNames } from './service'

export const GET_LOOKUP = 'searcher/GET_LOOKUP'
export interface GetLookup {
  type: typeof GET_LOOKUP
}

export const SET_LOOKUP = 'searcher/SET_LOOKUP'
export interface SetLookup {
  type: typeof SET_LOOKUP
  data: object[]
}

export const START_LOOKUP = 'searcher/START_LOOKUP'
export interface StartLookup {
  type: typeof START_LOOKUP
  data: ILookup
  criteria: string
  percentage: string | undefined
  tableLimit: number,
  lookupType: Source | undefined
  country: string | undefined
  inputIp: string | undefined
}

export const START_VERIFY = 'searcher/START_VERIFY'
export interface StartVerify {
  type: typeof START_VERIFY
  data: VerifiedNames,
  tableLimit: number,
}

export const START_SUMMARIZING = 'searcher/START_SUMMARIZING'
export interface StartSummarizing {
  type: typeof START_SUMMARIZING
  data: ApiDataSummarizing,
  tableLimit: number,
}

export const FETCH_RESULT_BY_ID = 'searcher/FETCH_RESULT_BY_ID'
export interface FetchResultBuId {
  type: typeof FETCH_RESULT_BY_ID
  id: string
}
export const FETCH_RESULT_BY_IDASYNC = 'searcher/FETCH_RESULT_BY_IDASYNC'
export interface FetchResultBuIdAsync {
  type: typeof FETCH_RESULT_BY_IDASYNC
  id: string,
  callback: any
}
export const FETCH_NOTIFICATION_RESULT = 'searcher/FETCH_NOTIFICATION_RESULT'
export interface FetchNotificationResult {
  type: typeof FETCH_NOTIFICATION_RESULT
  requestMonitorId: number
}

export const TOGGLE_VIEW_RESULT = 'searcher/TOGGLE_VIEW_RESULT'
export interface ToggleViewResult {
  type: typeof TOGGLE_VIEW_RESULT
  data: SearchResult | null
}

export const DELETE_RESULTS = 'searcher/DELETE_RESULTS'
export interface DeleteResults {
  type: typeof DELETE_RESULTS
  ids: number[]
  callback: () => void
}

export const SET_PAGE = 'searcher/SET_PAGE'
export interface SetPage {
  type: typeof SET_PAGE
  number: number
}

export const GET_PDF_DATA = 'searcher/GET_PDF_DATA'
export interface GetPDFData {
  type: typeof GET_PDF_DATA
  requestMonitorId: number
  apiKey: string
}

export const SET_PDF_DATA = 'searcher/SET_PDF_DATA'
export interface SetPDFData {
  type: typeof SET_PDF_DATA
  pdfData: any
}

export const GET_PDF = 'searcher/GET_PDF'
export interface GetPDF {
  type: typeof GET_PDF
  requestMonitorId: number
}

export const GET_LINK_PREVIEW = 'searcher/GET_LINK_PREVIEW'
export interface GetLinkPreview {
  type: typeof GET_LINK_PREVIEW
  link: string
  callback: (data: any) => void
}

export type SearcherAction =
  | GetLookup
  | SetLookup
  | StartVerify
  | StartSummarizing
  | StartLookup
  | FetchResultBuId
  | FetchResultBuIdAsync
  | FetchNotificationResult
  | ToggleViewResult
  | DeleteResults
  | SetPage
  | GetPDFData
  | SetPDFData
  | GetPDF
  | GetLinkPreview
