import { store } from '../index'
import {
  CombinedPhoneItem,
  CombinedPhoneResult,
  DeepWebDataItem,
  DeepWebLookupResult,
  EmailListItem,
  EmailListResult,
  IPGeolocationItem,
  IPGeolocationResult,
  IpPhoneGeolocationItem,
  IpPhoneGeolocationResult,
  IPsychoAnalysysResult,
  KYCItem,
  KYCResult,
  NameApiDataItem,
  NameApiLookupResult,
  NameDataItem,
  NameLookupResult, Phone, PhoneDataItem,
  PhoneLookupResult,
  PostDataItem,
  PostLookupResult, PsychoAnalysysItem, SentimentAnalysysItem, SentimentAnalysysResult, Source, SourcePercentage, WebCollectionResult,
} from './reducer'
import { formatDate, fromUnix } from '../../function/date'
import { CommonState } from '../common/reducer'
import moment from 'moment'
import { object, string } from 'joi'
import { getCorsImageUrl } from '../../component/searcher/RenderData'
import { getImageCardInfo, getLinkToProfile, getUrl, normalizeSocialNetwork } from '../../function/string'
import { validateDataHasMoreFields } from '../../function/object-validator'
import { image } from 'html2canvas/dist/types/css/types/image'


export const prepareCombineResultPhone = (row: any): PhoneLookupResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const res_sources: SourcePercentage[] = []
  const res_data: PhoneDataItem[] = []
  apiDataEntities?.forEach((el: any) => {
    if (el.from !== 'PLUTO') {
      res_data.push(el)
      res_sources.push({name : el.from, percentage : null})
    }
  })
  const res : PhoneLookupResult = {id, requestId, type, status, criteria, sources : res_sources, data : res_data, createdTime}
  return res
}

export const preparePhoneResult = (row: any): PhoneLookupResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria: phone,
    sources,
    apiDataEntities: data,
    createdTime,
  } = row

  const criteria = phone

  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareNameResult = (row: any): NameLookupResult => {
  const {
    id,
    requestId,
    type,
    status,
    apiDataEntities,
    criteria,
    sources,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat

  const data: NameDataItem[] = []

  apiDataEntities?.forEach((el: any) => {
    el.data?.forEach((dataEl: any) => {
      data.push({
        ...dataEl,
        from: el.from.toLowerCase(),
        created_time: formatDate(el.createdTime, `yyyy-MM-dd ${timeFormat}`),
      })
    })
  })

  return { id, requestId, type, status, criteria, data, sources, createdTime }
}

export const prepareNameApiResult = (row: any): NameApiLookupResult => {
  const {
    id,
    requestId,
    type,
    status,
    apiDataEntities,
    criteria,
    sources,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat

  const data: NameApiDataItem[] = []

  apiDataEntities?.forEach((el: any) => {
    el.data?.forEach((dataEl: any) => {
      data.push({
        ...dataEl,
        from: el.from.toLowerCase(),
        created_time: formatDate(el.createdTime, `yyyy-MM-dd ${timeFormat}`),
      })
    })
  })

  return { id, requestId, type, status, criteria, data, sources, createdTime }
}

export const preparePostResult = (row: any): PostLookupResult => {
  const {
    id,
    requestId,
    type,
    status,
    apiDataEntities,
    criteria,
    sources,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat

  const data: PostDataItem[] = []

  apiDataEntities?.forEach((el: any) => {
    el.data?.forEach((dataEl: any) => {
      data.push({
        ...dataEl,
        from: el.from.toLowerCase(),
        text: dataEl.text || '',
        image_url: dataEl.images_url ? dataEl.images_url[0] : undefined,
        timeStamp: formatDate(dataEl.timeStamp, `yyyy-MM-dd ${timeFormat}`),
      })
    })
  })

  return { id, requestId, type, status, criteria, data, sources, createdTime }
}

export const prepareСombineResultPluto = (row: any): CombinedPhoneResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat

  const data: CombinedPhoneItem[] = []


  apiDataEntities?.forEach((el: any) => {
    if (el.from === 'PLUTO') {
    el.data?.forEach((dataEl: any) => {
      Object.entries(dataEl).filter(el => el[1]).forEach(([label, value]) => {
        const isStringArray =
          Array.isArray(value) &&
          value?.length > 0 &&
          value.every((v) => {
            return typeof v === 'string'
          })
        if (isStringArray) {
          dataEl[label] = (value as Array<string>).join(', ')
        }
      })

      data.push({
        ...dataEl,
        created_time: formatDate(el.createdTime, `yyyy-MM-dd ${timeFormat}`),
        customrequestid: '',
        type: sources?.[0]?.name,
        social_network: '',
      })
    })
    } 
  })
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareDeepWebResult = (row: any): DeepWebLookupResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat

  const data: DeepWebDataItem[] = []

  apiDataEntities?.forEach((el: any) => {
    Array.isArray(el?.data) && (el.from === 'PLUTO' || el.from === 'HYDRA') &&
    el.data?.forEach((dataEl: any) => {
      Object.entries(dataEl).filter(el => el[1]).forEach(([label, value]) => {
        const isStringArray =
          Array.isArray(value) &&
          value?.length > 0 &&
          value.every((v) => {
            return typeof v === 'string'
          })
        if (isStringArray) {
          dataEl[label] = (value as Array<string>).join(', ')
        }
      })


      data.push({
        ...dataEl,
        created_time: formatDate(el.createdTime, `yyyy-MM-dd ${timeFormat}`),
        customrequestid: '',
        type: sources?.[0]?.name,
        social_network: '',
      })
    })
  })

  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareSentimentalAnalysisResult = (row: any): SentimentAnalysysResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat

  const data: SentimentAnalysysItem[] = []

  apiDataEntities.forEach((el : any) => {
    if (el.data) {
         data.push({
         text: el.data.text,
         entity: el.data.entity || '',
         sentiment: el.data.sentiment,
         status: el.data.status,
         summary: el.data.summary,
         topic: el.data.topic,
         createdTime: moment(el.createdTime).format('DD/MM/YYYY hh:mm:ss'),
       })
    }
  })

  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareKYCResult = (row: any): KYCResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const data : any = []
  apiDataEntities.forEach((el : any) => {

    if (el.from === 'kyc_company_enrich' && el.data.web_collection) {
         data.push({
          country : el.data.web_collection.country,
          domain : el.data.web_collection.domain,
          employees_count: el.data.web_collection.employees_count,
          industry: el.data.web_collection.industry,
          linkedin_url: el.data.web_collection.linkedin_url,
          locality: el.data.web_collection.locality,
          name: el.data.web_collection.name,
          year_founded: el.data.web_collection.year_founded,
          source_type: el.from,
       })
    }
    if (el.from === 'kyc_iban' && el.data.web_collection) {
      data.push({
       iban: el.data.web_collection.iban,
       is_valid: el.data.web_collection.is_valid,
       source_type: el.from,
    })
    }
    if (el.from === 'kyc_vat_validate' && el.data.web_collection) {
      data.push({
      vat_number: el.data.web_collection.vat_number,
      vat_valid: el.data.web_collection.valid,
      company_name: el.data.web_collection.company.name,
      comapany_address: el.data.web_collection.company.address,
      company_country_code: el.data.web_collection.country.code,
      company_country_name: el.data.web_collection.country.name,
      source_type: el.from,
    })
    }
  })
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareWebCollectionResult = (row: any): WebCollectionResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const data : any = []
  apiDataEntities.forEach((el : any) => {

    if (el.from === 'web_collection_screenshot' && el.data.web_collection) {
         data.push({
          image : el.data.web_collection,
          source_type: el.from,
       })
    }

    if (el.from === 'web_collection_scrape' && el.data.web_collection) {
      data.push({
        htmlCode : el.data.web_collection,
        source_type: el.from,
    })
 }
  })
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const preparePsychoAnalysysResult = (row: any): IPsychoAnalysysResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const data : PsychoAnalysysItem[] = []

  apiDataEntities.forEach((el : any) => {
    if (el.data && el.data.from && el.data.from === 'PSYCH' && 
          el.data.psychAnalyst && el.data.psychAnalyst.profiles && Array.isArray(el.data.psychAnalyst.profiles) && 
          el.data.psychAnalyst.profiles.length > 0) {
            const profiles : any[] = el.data.psychAnalyst.profiles 
            
            data.push({
              image: el.data.psychAnalyst.image ? el.data.psychAnalyst.image : null,
              levelOfDanger: profiles[0].levelOfDanger ? profiles[0].levelOfDanger : null,
              personName: profiles[0].personName ? profiles[0].personName : null,
              predictedCharacteristics: profiles[0].predictedCharacteristics ? profiles[0].predictedCharacteristics : null,
              psychologicalPortrait: profiles[0].psychologicalPortrait ? profiles[0].psychologicalPortrait : null,
            })
    }
  })

  apiDataEntities.forEach((el : any) => {
    if (el.data && el.data.from && el.data.from === 'SUMMARY' && 
          el.data.psychAnalyst && el.data.psychAnalyst.summaries && Array.isArray(el.data.psychAnalyst.summaries)
        && el.data.psychAnalyst.summaries.length > 0) {
        const summaries : any[] = el.data.psychAnalyst.summaries 
        data.push({
        image: el.data.psychAnalyst.image ? el.data.psychAnalyst.image : null,
        age: summaries[0].age ? summaries[0].age : null,
        country: summaries[0].country ? summaries[0].country : null,
        gender: summaries[0].gender ? summaries[0].gender : null,
        occupation: summaries[0].occupation ? summaries[0].occupation : null,
        summary: summaries[0].summary ? summaries[0].summary : null,
      })
    }
  })
  
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareIpGeolocationResult = (row: any): IPGeolocationResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const data : IPGeolocationItem[] = []
  apiDataEntities.forEach((el : any) => {
    if (el.data && el.data.web_collection){   
      data.push({
        ip_address: el.data.web_collection.ip_address,
        city: el.data.web_collection.city,
        city_geoname_id: el.data.web_collection.city_geoname_id,
        region: el.data.web_collection.region,
        region_geoname_id: el.data.web_collection.region_geoname_id,
        region_iso_code: el.data.web_collection.region_iso_code,
        postal_code: el.data.web_collection.postal_code,
        country: el.data.web_collection.country,
        country_code: el.data.web_collection.country_code,
        country_geoname_id: el.data.web_collection.country_geoname_id,
        country_is_eu: el.data.web_collection.country_is_eu,
        continent: el.data.web_collection.continent,
        continent_code: el.data.web_collection.continent_code,
        continent_geoname_id: el.data.web_collection.continent_geoname_id,
        latitude: el.data.web_collection.latitude,
        longitude: el.data.web_collection.longitude,
        is_vpn: el.data.web_collection.security.is_vpn,
      })
    }
  })
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareIpPhoneGeolocationResult = (row: any): IpPhoneGeolocationResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const data : IpPhoneGeolocationItem[] = []
  apiDataEntities.forEach((el : any) => {
    if (el.data.web_collection) {
       data.push({
        ip: el.data.web_collection.ip,
        ipCountry: el.data.web_collection.ipCountry,
        ipCountryCode: el.data.web_collection.ipCountryCode,
        
        phone: el.data.web_collection.phone,
        phoneCountry: el.data.web_collection.phoneCountry,
        phoneCountryCode: el.data.web_collection.phoneCountryCode,
        status: el.data.web_collection.status,

       })
      } else {
        data.push({
          status: el.data.email_validator.status,
         })
      }
    })
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

export const prepareEmailListResult = (row: any): EmailListResult => {
  const {
    id,
    requestId,
    type,
    status,
    criteria,
    sources,
    apiDataEntities,
    createdTime,
  } = row
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const data : EmailListItem[] = []
  apiDataEntities.forEach((el : any) => {
    if (el.data.email) {
       data.push({
        email: el.data.email,
       })
      } else {
        data.push({
          email: "TEST EMAIL",
         })
      }
    })
  return { id, requestId, type, status, criteria, sources, data, createdTime }
}

const socials = ['fb', 'instagram', 'linkedin', 'twitter', 'ok', 'vk']
const lmApps = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'hiya',
  'imo',
  'tamtam',
  'botim',
]

const plutoSources = ["password","telegramid","linkedinid","email","phone","facebookid","vkid","fullname","twitterid","instagramid"]

export interface VerifiedNames {
  source: string[]
  name: string[]
  requestId: string
  checkPrice: number
}

export interface ApiDataSummarizing {
  apiData : any
  summarizePrice: number
  requestId: string
}

export interface Name {
  app?: Source
  name: string
}
export interface Image {
  app?: Source
  image: string
}
interface LastOnline {
  app: Phone
  online: any
}
interface Birthday {
  app: Phone
  birthday: string
}
interface SocialNetwork {
  app: string
  socialNetwork : any,
  url?: string,
}
interface Email {
  app?: string
  email: string
}
interface Address {
  app: string
  address: string
}
interface Url {
  link: string
  title: string
}

export type PreparedCommonResult = {
  foundSources: string[];
  notFoundSources: string[];
  name: string,
  names: Name[];
  images: Image[],
  lastOnline: LastOnline[],
  birthday: Birthday[],
  socialNetworks: SocialNetwork[],
  emails: Email[],
  addresses: Address[],
  urls: Url[],
  base64: string[],
  createdDate: string,
  deepwebType: string,
  identicalImages?: any[],
  similarImages?: any[];
  phones: Name[];
  useTaro?: boolean,
  verifiedServices?: string[],
  isVerifiedName? : boolean,
  isApiSummary? : boolean,
  verifiedScore? : number
  finalClassification?: string,
  criteriaCountry?: string,
}

export const prepareCommonResult = (row: any): PreparedCommonResult => {
  const {apiDataEntities = [], type, createdTime, criteria, sources} = row
  const apps = sources.map((src: { name: any }) => src.name)
  const returnedApps = apiDataEntities.map((d: any) => typeof d === 'object' && d && d.from)
  const output: PreparedCommonResult = {
    name: '',
    foundSources: [],
    notFoundSources: [],
    names: [],
    images: [],
    lastOnline: [],
    birthday: [],
    socialNetworks: [],
    emails: [],
    addresses: [],
    urls: [],
    createdDate: '',
    deepwebType: '',
    identicalImages: [],
    similarImages: [],
    phones: [],
    useTaro: false,
    verifiedServices: [],
    base64: [],
    isVerifiedName: false,
    isApiSummary: false,
    verifiedScore: 0,
    finalClassification: '',
    criteriaCountry: '',
  }
  // for lms that sometimes do not return data at all
  apps.forEach((a: string) => {
    if (!returnedApps.includes(a) && lmApps.includes(a)){
      output.notFoundSources.push(a)
    }
  })

  //CREATED DATE
  if (createdTime) {
    output.createdDate = moment(createdTime).format('DD/MM/YYYY HH:mm:ss')
  }

  //NAME DEFAULT
  output.name = criteria || ''

  //PHONE
  if (['phone', 'combined_email', 'combined_phone'].includes(type)) {
    apiDataEntities
      .filter((a: any) => !['pluto', 'hydra'].includes(a.from.toLowerCase()))
      .forEach((entity: any) => {
        if (entity.from === 'web_collection_email' && entity.data['web_collection']) {
          entity.data['web_collection_email'] = entity.data['web_collection']
          entity.data.from = 'web_collection_email'
        }
        if ( entity.from === 'web_collection_phone' && entity.data['web_collection']) {
          entity.data['web_collection_phone'] = entity.data['web_collection']
          entity.data.from = 'web_collection_phone'
        }
        if ( entity.from === 'mail_reveal' && entity.data['mail_reveal']) {
          entity.data['mail_reveal'] = entity.data['emailSearchResult']
          entity.data.from = 'mail_reveal'
        }

        if (entity.from !== 'VERIFICATION') {
        try {
        ['name', 'nickname','email'].forEach((key: string) => {
          ["undefined, ",", undefined", "null, ",", null", "undefined", "null"].forEach((undefnull) => {
            if (key in entity.data[entity.from] && entity.data[entity.from][key] && entity.data[entity.from][key].includes(undefnull)){
              entity.data[entity.from][key] = entity.data[entity.from][key].replace(undefnull, "")
            }
          })
        })
      } catch (e) {

      }
      }

        //collect foundSources
        //if (!entity?.isFailed) {
          if (entity.data[entity.from] && Object.keys(entity.data[entity.from]).length > 0) {
            switch (entity.from) {
              case 'luna':
                if (
                  entity.data[entity.from]?.person
                  || entity.data[entity.from]?.possiblePersons
                  || entity.data[entity.from]?.sources
                ) {
                  output.foundSources.push(entity.from?.toLowerCase())
                } else {
                  output.notFoundSources.push(entity.from?.toLowerCase())
                }
                break
              case 'taro':
                if (entity.data[entity.from] && entity.data[entity.from]?.data && Object.keys(entity.data[entity.from].data).length > 0) {
                  output.foundSources.push(entity.from?.toLowerCase())
                } else {
                  output.notFoundSources.push(entity.from?.toLowerCase())
                }
                break
              case 'getcontact':
                if(entity.data[entity.from] && 
                  (
                    !entity.data[entity.from]?.isUser &&
                    (!entity.data[entity.from]?.displayName &&
                    !entity.data[entity.from]?.email &&
                    !entity.data[entity.from]?.name)
                  )
                  ){
                  output.notFoundSources.push(entity.from?.toLowerCase())
                }
                else {
                  output.foundSources.push(entity.from?.toLowerCase())
                }
                break
              default:
                if (!["web_collection_email","web_collection_phone","ip"].includes(entity.from) &&
                  !validateDataHasMoreFields(entity)){
                  output.notFoundSources.push(entity.from?.toLowerCase())
                }else{
                  output.foundSources.push(entity.from?.toLowerCase())
                }
            }
          }
        //}
        //collect names
        if (entity.data[entity.from]?.name) {
          output.names.push({app: entity.from, name: entity.data[entity.from]?.name})
        }
        if (entity.data[entity.from]?.nickname) {
          output.names.push({app: entity.from, name: entity.data[entity.from]?.nickname})
        }
        if (entity.data[entity.from]?.names && entity.from === 'tamtam') {
          if (Array.isArray(entity.data[entity.from].names)) {
            entity.data[entity.from].names
              .filter((tam: {type: number}) => tam.type === 2)
              .forEach((tam: {name: string}) => {
              output.names.push({app: entity.from, name: tam.name})
            })
          } else if (typeof entity.data[entity.from].names === 'object') {
            Object.keys(entity.data[entity.from].names).forEach(key => {
              const name = entity.data[entity.from].names[key]
              if (name?.name && name?.type === 2) {
                output.names.push({app: entity.from, name: name.name})
              }
            })
          }
        }
        if (entity.data[entity.from]?.names && entity.from === 'callapp') {
          if (Array.isArray(entity.data[entity.from].names)) {
            entity.data[entity.from].names
              .forEach((call: {name: string}) => {
                output.names.push({app: entity.from, name: call.name})
              })
          }
        }
        if (entity.from === 'tc' && entity.data[entity.from]?.contact?.name) {
          output.names.push({app: entity.from, name: entity.data[entity.from]?.contact?.name})
        }
        if ((entity.from === 'hiya' || entity.from === 'getcontact') && entity.data[entity.from]?.displayName) {
          output.names.push({app: entity.from, name: entity.data[entity.from]?.displayName})
        }
        if (entity.from === 'luna' && entity.data[entity.from]?.person?.names?.length > 0) {
          entity.data[entity.from]?.person?.names.forEach((name: any) => {
            output.names.push({app: entity.from, name: name.display})
          })
        }
        if ( (entity.from === 'caller_api' && entity.data && entity.data.callerApi) || 
              (entity.from === 'kraken' && entity.data && entity.data.kraken) ) {
          const callerApi = entity.data.callerApi || entity.data.kraken
          if (callerApi.callapp) {
            const callapp = callerApi.callapp
            if (callapp && callapp.name) {
              output.names.push({app: entity.from, name: callapp.name})
            }
            if (callapp && callapp.emails && Array.isArray(callapp.emails)) {
              callapp.emails.forEach( ( el : any ) => {
                if (el && el.email) {
                  output.emails.push({app: entity.from, email: el.email})
                }
              } )
            }
            if (callapp && callapp.addresses && Array.isArray(callapp.addresses)) {
              callapp.addresses.forEach( (el : any) => {
                if (el.street) {
                  output.addresses.push({app: entity.from, address: el.street})
                }
              } )
            }
            if (callapp && callapp.facebookID && callapp.facebookID.id) {
              output.socialNetworks.push({app: 'facebook', socialNetwork: callapp.facebookID.id, url: getLinkToProfile('facebook', callapp.facebookID.id)})
            }

          if (callapp && callapp.photoUrl) {
            output.images.push({app: entity.from, image: callapp.photoUrl})
          }
          }
          if (callerApi && callerApi.truecaller && callerApi.truecaller.country) {
            output.addresses.push({app: entity.from, address: callerApi.truecaller.country})
          }
          if (callerApi && callerApi.hiya && callerApi.hiya.name) {
            output.names.push({app: entity.from, name: callerApi.hiya.name})
          }
          if (callerApi && callerApi.callerapi && callerApi.callerapi.name) {
            output.names.push({app: entity.from, name: callerApi.callerapi.name})
          }
          if (callerApi && callerApi.kraken && callerApi.kraken.name) {
            output.names.push({app: entity.from, name: callerApi.kraken.name})
          }
          if (callerApi && callerApi.viewcaller && Array.isArray(callerApi.viewcaller)) {
            const viewcaller = callerApi.viewcaller[0]
            if (viewcaller && viewcaller.name) {
              output.names.push({app: entity.from, name: viewcaller.name})
            }
            if (viewcaller && viewcaller.names && Array.isArray(viewcaller.names)) {
              viewcaller.names.forEach( (el: any) => {
                if (el.name) {
                  output.names.push({app: entity.from, name: el.name})
                }
              } )
            }
          }
        }
        //collect verified services
        if (entity.from === 'taro' && entity.data[entity.from]?.data) {
          const accounts = entity.data[entity.from]?.data?.account_details
          for (const sn in accounts) {
            if (accounts[sn].registered) {
              output.verifiedServices?.push(sn)
            }
          }
          //output.names.push({app: entity.from, name: entity.data[entity.from]?.displayName})
        }
        if (entity.from === 'taro' && Object.keys(entity.data[entity.from]?.data || {}).length > 0) {
          output.useTaro = true
        }

        //collect images
        if (entity.from === 'skype') {
          const baseUrl = entity.data[entity.from].avatar?.split('?')[0]
          const urlParams = new URLSearchParams(entity.data[entity.from].avatar?.split('?')[1])
          if (urlParams && baseUrl && (urlParams.get('returnDefaultImage') === "false" )) {
            urlParams.set('returnDefaultImage', 'true')
            entity.data[entity.from].avatar =  baseUrl + '?' + urlParams
          }
        }
        if (entity.from === 'botim') {
          if (entity.data[entity.from].avatar) {
            entity.data[entity.from].avatar = entity.data[entity.from].avatar.replace('https://', 'http://')
          }
        }

        ['avatar', 'image', 'photoUrl', 'photo'].forEach(t => {
          if (entity.data[entity.from]?.[t]) {
            output.images.push({app: entity.from, image: entity.data[entity.from]?.[t]})
          }
        })
        if (entity.from === 'tc' && entity?.data[entity.from]?.contact?.image) {
          output.images.push({app: entity.from, image: entity?.data[entity.from]?.contact?.image})
        }
        if (entity.from === 'callapp' && entity?.data[entity.from]?.photoUrls?.photoUrl) {
          output.images.push({app: entity.from, image: entity?.data[entity.from]?.photoUrls?.photoUrl})
        }
        if (entity.from === 'taro' && entity?.data[entity.from]?.data?.account_details?.google?.photo) {
          output.images.push({app: entity.from, image: entity?.data[entity.from]?.data?.account_details?.google?.photo})
          entity.data[entity.from].avatar = entity?.data[entity.from]?.data?.account_details?.google?.photo
        }


        //collect last online
        ['online', 'lastUpdateTime'].forEach(t => {
          if (entity.data[entity.from]?.[t]) {
            if (moment(entity.data[entity.from]?.[t]).isValid()) {
              output.lastOnline.push({app: entity.from, online: moment(entity.data[entity.from]?.[t]).format('DD/MM/YYYY hh:mm:ss')})
            }
          }
        })
        if (entity.data[entity.from]?.last_seen) {
          const online = entity.data[entity.from]?.last_seen
          if (![['Offline', 'Online']].includes(online) && online !== 'Recently') {
            if (online === 'LastWeek') {
              output.lastOnline.push({ app: entity.from, online: 'Last Week' })
            } else  if (online === 'LastMonth') {
              output.lastOnline.push({ app: entity.from, online: 'Last Month' })
            } else {
              if (moment(online).isValid()) {
                output.lastOnline.push({ app: entity.from, online: moment(online).format('DD/MM/YYYY hh:mm:ss') })
              }
            }
          }
          if (entity.from !== 'tamtam') {
            output.lastOnline.push({ app: entity.from, online })
          }
        }

        //collect birthdays
        if (entity.data[entity.from]?.birthday) {
          const birthday = entity.data[entity.from]?.birthday
          if (birthday && typeof birthday === 'string') {
            output.birthday.push({app: entity.from, birthday})
          }
          if (birthday && entity.from === 'callapp' && birthday.formattedYear) {
            output.birthday.push({
              app: entity.from,
              birthday: `${birthday?.formattedYear}-${birthday?.formattedMonth}-${birthday?.formattedDay}`,
            })
          }
        }

        //collect social networks
        if (entity.data[entity.from]?.socialNetworkIds) {
          const ids = entity.data[entity.from]?.socialNetworkIds
          if (ids?.length) {
            ids.forEach((id: string) => {
              output.socialNetworks.push({app: entity.from, socialNetwork: id})
            })
          }
        }
        if (entity.from === 'skype') {
          if (entity.data[entity.from]?.skype_id) {
            output.socialNetworks.push({app: entity.from, socialNetwork: entity.data[entity.from]?.skype_id})
          }
        }
        if (entity.from === 'callapp') {
          ['facebookID', 'twitterScreenName', 'vkID'].forEach(sn => {
            if (entity.data[entity.from]?.[sn]?.id) {
              output.socialNetworks.push({app: sn, socialNetwork: entity.data[entity.from]?.[sn]?.id, url: getLinkToProfile(sn, entity.data[entity.from]?.[sn]?.id)})
            }
          })
        }
        if (entity.from === 'eyecon'){
          if (entity.data['eyecon'].facebook_id || entity.data['eyecon'].social_id){
            const fbId = entity.data['eyecon'].facebook_id || entity.data['eyecon'].social_id
            output.socialNetworks.push({app: 'facebook', socialNetwork: fbId, url: getLinkToProfile('facebook', fbId)})
          }
        }
        if (entity.from === 'whatsapp' && entity.data[entity.from]?.business?.profile?.linked_accounts?.ig_professional) {
          const whatsappInsta = entity.data[entity.from]?.business?.profile?.linked_accounts?.ig_professional
          if (whatsappInsta.ig_handle) {
          output.socialNetworks.push({app: 'instagram', socialNetwork: whatsappInsta.ig_handle, url: getLinkToProfile('instagram', whatsappInsta.ig_handle)})
          }
        }
        //collect emails
        if (entity.data[entity.from]?.email) {
          output.emails.push({app: entity.from, email: entity.data[entity.from]?.email})
        }

        if (entity.from === 'tc' && entity.data[entity.from]?.contact?.internetAddresses) {
          if (Array.isArray(entity.data[entity.from]?.contact?.internetAddresses)) {
            entity.data[entity.from]?.contact?.internetAddresses?.forEach((address: any) => {
              if (address?.service === 'email' && address.id) {
                output.emails.push({app: entity.from, email: address.id})
              }
            })
          } else if (typeof entity.data[entity.from]?.contact?.internetAddresses === 'object') {
            Object.keys(entity.data[entity.from]?.contact?.internetAddresses).forEach(key => {
              const addr = entity.data[entity.from]?.contact?.internetAddresses[key]
              if (addr?.service === 'email' && addr.id) {
                output.emails.push({app: entity.from, email: addr.id})
              }
            })
          }
        }
        if (entity.from === 'callapp' && entity.data[entity.from]?.emails) {
          if (Array.isArray(entity.data[entity.from]?.emails)) {
            entity.data[entity.from]?.emails?.forEach((email: any) => {
              output.emails.push({app: entity.from, email: email.email})
            })
          }
        }
        if (entity.from === 'luna' && entity.data[entity.from]?.person?.emails?.length) {
          entity.data[entity.from]?.person?.emails.forEach((email: any) => {
            output.emails.push({app: entity.from, email: email.address})
          })
        }

        if (entity.from === 'whatsapp' && entity.data[entity.from]?.business?.profile?.email) {
          output.emails.push({app: entity.from, email: entity.data[entity.from]?.business?.profile?.email})
        }

        //collect phones
        if (entity.from === 'luna' && entity.data[entity.from]?.person?.phones?.length) {
          entity.data[entity.from]?.person?.phones.forEach((phone: any) => {
            output.phones.push({app: entity.from, name: phone.displayInternational})
          })
        }

        //collect addresses
        if (entity.from === 'whatsapp' && entity.data[entity.from]?.business?.profile?.address) {
          output.addresses.push({app: entity.from, address: entity.data[entity.from]?.business?.profile?.address})
        }

        if (entity.from === 'tc' && entity.data[entity.from]?.contact?.addresses) {
          if (Array.isArray(entity.data[entity.from]?.contact?.addresses)) {
            entity.data[entity.from]?.contact?.addresses?.forEach((address: any) => {
              if (address?.address) {
                output.addresses.push({app: entity.from, address: address.address})
              }
            })
          } else if (typeof entity.data[entity.from]?.contact?.addresses === 'object') {
            Object.keys(entity.data[entity.from]?.contact?.addresses).forEach(key => {
              const addr = entity.data[entity.from]?.contact?.addresses[key]
              if (addr?.address) {
                output.addresses.push({app: entity.from, address: addr.address})
              }
            })
          }
        }
        if (entity.from === 'skype') {
          if (entity.data[entity.from]?.country || entity.data[entity.from]?.city) {
            const address = [entity.data[entity.from]?.city, entity.data[entity.from]?.country].filter(el => el).join(', ')
            if (address) {
              output.addresses.push({app: entity.from, address})
            }
          }
        }
        if (entity.from === 'hiya') {
          if (entity.data[entity.from]?.profileDetails?.location) {
            output.addresses.push({app: entity.from, address: entity.data[entity.from]?.profileDetails?.location})
          }
        }
        if (entity.from === 'callapp') {
          if (Array.isArray(entity.data[entity.from]?.addresses)) {
            entity.data[entity.from]?.addresses?.forEach((address: any) => {
              if (address?.street) {
                output.addresses.push({app: entity.from, address: address.street})
              }
            })
          }
        }
        if (entity.from === 'luna' && entity.data[entity.from]?.person?.addresses?.length) {
          entity.data[entity.from]?.person?.addresses.forEach((address: any) => {
            output.addresses.push({app: entity.from, address: address.display})
          })
        }

        //collect websites
        if (entity.from === 'whatsapp' && entity.data[entity.from]?.business?.profile?.website) {
          output.urls.push({link: getUrl(entity.data[entity.from]?.business?.profile?.website), title: entity.data[entity.from]?.business?.profile?.website})
        }

        if (entity.from === 'callapp' && entity.data[entity.from]?.websites) {
          entity.data[entity.from]
            ?.websites
            //?.filter((elem: any) => elem.type === 1)
            ?.forEach(({websiteUrl, type = 1}: any) => {
              if (type === 1) {
                output.urls.push({link: getUrl(websiteUrl), title: websiteUrl})
              }
            })
        }
        if (entity.from === 'luna' && entity.data[entity.from]?.sources?.length) {
          entity.data[entity.from]?.sources.forEach((source: any) => {
            if (source.category === "personal_profiles") {
              //output.urls.push({link: getUrl(source.originUrl), title: source.domain})
              output.socialNetworks.push({app: normalizeSocialNetwork(source.domain), socialNetwork: source?.domain, url: source.originUrl})
            }
            if (source.category === 'web_pages') {
              if (source.originUrl && (source.name || source.domain)){
                output.urls.push({link: getUrl(source.originUrl), title: source?.name || source?.domain})
              }
            }
            if (source?.images?.length) {
              source?.images.forEach((image: any) => {
                output.images.push({app: entity.from, image: image.url})
              })
            }
          })
        }
        if (entity.from === 'VERIFICATION') {
          output.isVerifiedName = true
          output.finalClassification = entity.data.verifier.finalClassification 
          output.verifiedScore = entity.data.verifier.score
        }
      },
    )


    //collect name by default
    if (output.names?.length) {
      output.name = output.names[0].name
    }

    //collect not found sources
    if (['phone', 'combined_phone'].includes(type)) {
      apps.forEach((app:string) => {
        if (!output.foundSources.includes(app)) {
          output.notFoundSources.push(app)
        }
      })
    }
  }

  if (['name', 'combined_name', 'combined_id', 'name_by_country'].includes(type)) {

    if (type === 'name_by_country') {
      if (apiDataEntities?.length > 0) {
        const [entity] = apiDataEntities
        if (entity?.data && entity?.data?.length) {
          const [first] = entity.data
          output.criteriaCountry = first?.addressCountry || first?.countryCode

          entity.data.forEach((item: any) => {
            //collect addresses
            const address = []
            if (item.addressCity) {
              address.push(item.addressCity)
            }
            if (item.addressCountry) {
              address.push(item.addressCountry)
            }
            if (item.address) {
              address.push(item.address)
            }
            if (item.city) {
              address.push(item.city)
            }
            if (item.country) {
              address.push(item.country)
            }
            if (address.length > 0) {
              output.addresses.push({app: entity.from?.toLowerCase(), address: address.join(', ')})
            }

            //collect names
            if (item.fullName) {
              output.names.push({app: entity.from?.toLowerCase(), name: item.fullName})
            }

            //collect emails
            if (item.email) {
              output.emails.push({app: entity.from?.toLowerCase(), email: item.email})
            }
            if (item.emailAddress) {
              output.emails.push({app: entity.from?.toLowerCase(), email: item.emailAddress})
            }

            //collect phones
            if (item.phone) {
              output.phones.push({app: entity.from?.toLowerCase(), name: item.phone})
            }

            //collect birthdays
            if (item.birthDate) {
              output.birthday.push({app: entity.from?.toLowerCase(), birthday: item.birthDate})
            }

            //collect urls
            if (item.website) {
              output.urls.push({link: `https://${item.website?.replace('https://')}`, title: item.website})
            }

            //collect sn
            if (item.VkID) {
              output.socialNetworks.push({app: 'vk', socialNetwork: 'vkid', url: getLinkToProfile('vkid', item.VkID)})
            }
            if (item.instagramUsername) {
              output.socialNetworks.push({app: 'instagram', socialNetwork: 'instagram', url: getLinkToProfile('instagram', item.instagramUsername)})
            }
          })

        }
      }
    }


    apiDataEntities.forEach((entity: any) => {
      const from = entity.from?.toLowerCase()

      //collect foundSources
      //if (!entity?.isFailed) {
        if (Array.isArray(entity.data) && entity.data.length > 0) {
          output.foundSources.push(from)
        } else {
          output.notFoundSources.push(from)
        }
      //} else {
        //output.notFoundSources.push(from)
      //}

      //collect profiles
      entity.data && entity.data
        .filter((el: any) => el.type === 'ProfileItem')
        .forEach((item: any) => {
          if (item?.profileImageUrl) {
            output.images.push({app: from, image: getCorsImageUrl(item?.profileImageUrl)})
          }
          if (item?.profileUrl) {
            output.socialNetworks.push({app: from, socialNetwork: item?.profile_id, url: item.profileUrl})
          }
          if (item?.profileName) {
            output.names.push({app: from, name: item?.profileName})
          }
          if (item?.profileBirthday) {
            output.birthday.push({app: from, birthday: item.profileBirthday})
          }
          if (item?.last_online_status) {
            output.lastOnline.push({app: from, online: item.last_online_status})
          }
          if (item?.profileData && item.profileData?.contact_names?.length) {
            item.profileData?.contact_names?.forEach((name: string) => {
              output.names.push({app: from, name})
            })
          }
          if (item?.profileData && item.profileData?.web_results?.length) {
            item.profileData?.web_results?.forEach((web: any) => {
              if (web.result_image) {
                output.images.push({app: from, image: web.result_image })
              }
            })
          }
          if (item?.profileData?.facebook_id) {
            const clearSn = item?.profileData?.facebook_id?.replace("\n", '')?.trim()
            if (clearSn) {
              output.socialNetworks.push({app: 'fb', socialNetwork: clearSn, url: getLinkToProfile('facebook', clearSn)})
            }
          }
          if (item?.profileData?.instagram_id) {
            const clearSn = item?.profileData?.instagram_id?.replace("\n", '')?.trim()
            if (clearSn) {
              output.socialNetworks.push({app: 'instagram', socialNetwork: clearSn, url: getLinkToProfile('instagram', clearSn)})
            }
          }
          if (item?.profileData?.linkedin_id) {
            const clearSn = item?.profileData?.linkedin_id?.replace("\n", '')?.trim()
            if (clearSn) {
              output.socialNetworks.push({app: 'linkedin', socialNetwork: clearSn, url: getLinkToProfile('linkedin', clearSn)})
            }
          }
          if (item?.profileData?.twitter_id) {
            const clearSn = item?.profileData?.twitter_id?.replace("\n", '')?.trim()
            if (clearSn) {
              output.socialNetworks.push({app: 'twitter', socialNetwork: clearSn, url: getLinkToProfile('twitter_id', clearSn)})
            }
          }
        })
      //collect album images
      /*entity.data
        .filter((el: any) => el.type === 'ProfileAlbumItem')
        .forEach((item: any) => {
          if (item?.image_urls) {
            item?.image_urls.forEach((img: string) => {
              output.images.push({app: from, image: getCorsImageUrl(img)})
            })
          }
        })*/
    })

  }

  if (type === 'post') {
    apiDataEntities.forEach((entity: any) => {
      const from = entity.from?.toLowerCase()

      //collect foundSources
      
      if (
        !entity?.isFailed
        && entity.data
        && entity.data.filter((el: any) => el.type === 'PostItem').length > 0
      ) {
        output.foundSources.push(from)
      }

      //collect images
      if (entity.data) {
        entity.data
          .filter((el: any) => el.type === 'PostItem')
          .forEach((item: any) => {
            const count = output.images.filter((img) => img.app === from).length
            if (item?.original_post_user_image && count < 2) {
              //output.images.push({app: from, image: getCorsImageUrl(item?.original_post_user_image)})
            }
            if (item?.images_url) {
              item?.images_url.forEach((img: string) => {
                if (count < 2) {
                  output.images.push({app: from, image: getCorsImageUrl(img)})
                }
              })
            }
            /*if (item?.posted_by_profile_url) {
              output.socialNetworks.push({app: from, socialNetwork: item?.original_post_user_name, url: item.posted_by_profile_url})
            }*/
          })
      }
    })
  }

  if (['deepweb', 'combined_email', 'combined_name', 'combined_phone', 'combined_id'].includes(type)) {

    sources.forEach((src: {name: string})=> {
      if (plutoSources.includes(src.name)){
        output.deepwebType = src.name
      }
    })

    apiDataEntities.forEach((entity: any) => {
      const from = entity.from?.toLowerCase()
      //collect foundSources
      Array.isArray(entity.data) &&
      entity.data
        .forEach((item: any) => {
          const source = item?.customrequestid && item?.social_network && item?.type ? from : 'pluto'
          if (item?.FacebookId) {
            item.facebook_id = item.FacebookId
            delete item.FacebookId
          }
          if (item?.linkedinId) {
            item.linkedin_id = item.linkedinId
            delete item.linkedinId
          }
          if (item?.vkId) {
            item.vk_id = item.vkId
            delete item.vkId
          }
          if (item?.telegramId) {
            item.telegram_id = item.telegramId
            delete item.telegramId
          }

          //sources
          if (item?.facebook_id) {
            output.socialNetworks.push({app: 'fb', socialNetwork: item.facebook_id, url: getLinkToProfile('facebook_id', item.facebook_id)})
            //source = 'fb'
          }
          /*if (item?.FacebookId) {
            output.socialNetworks.push({app: 'fb', socialNetwork: item.FacebookId, url: getLinkToProfile('facebook_id', item.FacebookId)})
            source = 'fb'
          }*/
          if (item?.telegram_id) {
            output.socialNetworks.push({app: 'telegram', socialNetwork: item.telegram_id, url: getLinkToProfile('telegram_id', item.telegram_id)})
            //source = 'telegram'
          }
          if (item?.vk_id) {
            output.socialNetworks.push({app: 'vk', socialNetwork: item.vk_id, url: getLinkToProfile('vk_id', item.vk_id)})
            //source = 'vk'
          }
          if (item?.VkId) {
            output.socialNetworks.push({app: 'vk', socialNetwork: item.VkId, url: getLinkToProfile('vk_id', item.VkId)})
            //source = 'vk'
          }
          if (item?.linkedinUsername) {
            output.socialNetworks.push({app: 'linkedin', socialNetwork: item.linkedinUsername, url: getLinkToProfile('linkedinUsername', item.linkedinUsername)})
            //source = 'linkedin'
          } else if (item?.linkedin_id) {
            output.socialNetworks.push({app: 'linkedin', socialNetwork: item.linkedin_id, url: getLinkToProfile('linkedinUsername', item.linkedin_id)})
            //source = 'linkedin'
          }
          if (item?.twitter_username) {
            output.socialNetworks.push({app: 'twitter', socialNetwork: item.twitter_username, url: getLinkToProfile('twitter_username', item.twitter_username)})
            //source = 'twitter'
          }
          if (item?.twitterUsername) {
            output.socialNetworks.push({app: 'twitter', socialNetwork: item.twitterUsername, url: getLinkToProfile('twitter_username', item.twitterUsername)})
            //source = 'twitter'
          }
          if (item?.instagramUsername) {
            output.socialNetworks.push({app: 'instagram', socialNetwork: item.instagramUsername, url: getLinkToProfile('instagramUsername', item.instagramUsername)})
          } else if (item?.id) {
            output.socialNetworks.push({app: 'instagram', socialNetwork: item.id, url: getLinkToProfile('id', item.id)})
            //source = 'instagram'
          }

          if (source && !output.foundSources.includes(source)) {
            output.foundSources.push(source.toLowerCase())
          }

          //names
          const name: string = [item?.first_name, item?.last_name].join(' ')
          if (name && name.trim()) {
            output.names.push({app: source, name})
          }
          const nameC: string = [item?.firstName, item?.lastName].join(' ')
          if (nameC && nameC.trim()) {
            output.names.push({app: source, name: nameC})
          }
          if (item?.fullName) {
            output.names.push({app: source, name: item.fullName})
          }

          if (item?.tags) {
            output.names.push({app: source, name: item.tags})
          }
          //images
          if (item?.default_profile_image) {
            output.images.push({app: source, image: item.default_profile_image})
          }
          if (item?.profile_image_url_https) {
            output.images.push({app: source, image: item.profile_image_url_https})
          }

          //emails
          if (item?.email) {
            output.emails.push({email: item.email, app: source})
          }
          if (item?.emailAddress) {
            item?.emailAddress?.split(',').forEach((em: string) => {
              output.emails.push({email: em, app: source})
            })
          }
          //urls
          if (item?.site_url) {
            output.urls.push({link: getUrl(item?.site_url), title: item?.site_url})
          }
          if (item?.social_urls) {
            item?.social_urls.forEach((url: any) => {
              output.urls.push({link: url.url, title: url.domain})
            })
          }

          //address
          if (item?.address) {
            output.addresses.push({app: source, address: item.address})
          }
          if (item?.location) {
            output.addresses.push({app: source, address: item.location})
          }

        })
        if (Array.isArray(entity.data) && entity.data.length === 0) {
          output.notFoundSources.push(from)
        }


        if (entity.data && entity.data.emailSearchResult) {
          const source = 'mail_reveal'
          output.foundSources.push(source.toLowerCase())
          const company = entity.data.emailSearchResult.company ? entity.data.emailSearchResult.company : null 
          const person = entity.data.emailSearchResult.person ? entity.data.emailSearchResult.person : null 
       
          if (person) {
          const firstName = person.firstName ? person.firstName : ""
          const lastName = person.lastName ? person.lastName : ""
          if (firstName !== "" || lastName !== "") {
            output.names.push({app: source, name: `${firstName} ${lastName}`})
          }
          if (person.publicIdentifier) {
            output.socialNetworks.push({app: 'linkedin', socialNetwork: person.publicIdentifier, url: getLinkToProfile('linkedinUsername', person.publicIdentifier)})
          } 

          if (person.location) {
            output.addresses.push({app: source, address: person.location})
          }
          
          if (person.photoUrl) {
            output.images.push({app: source, image: person.photoUrl})
          }
        }
        if (company) {
          if (company.headquarter) {
            const city = company.headquarter.city ? company.headquarter.city : ""
            const country = company.headquarter.country ? company.headquarter.country : ""
            const geographicArea = company.headquarter.geographicArea ? company.headquarter.geographicArea : ""
            const postalCode = company.headquarter.postalCode ? company.headquarter.postalCode : ""
            const street1 = company.headquarter.street1 ? company.headquarter.street1 : ""
            const addressParts = [street1, city, geographicArea, postalCode, country]
              .filter(part => part) // Remove empty values
              .join(', ')
            output.addresses.push({ app: source, address: addressParts })
          }
        }
        }
    })

    if (
      apiDataEntities.filter((a: any) => ['pluto', 'hydra'].includes(a.from.toLowerCase())).length === 0
      && !output.foundSources.includes('pluto') && output.deepwebType !== ''
    ) {
      output.notFoundSources.push('pluto')
    }
  }

  if (['face_search', 'photo_searcher'].includes(type)) {
    apiDataEntities.forEach((entity: any) => {
      if (entity.data) {
        entity.data
          .forEach((item: any) => {
            if (!item.photo_id || item.photo_id === -1) {
              item.photo_id = item.face
            }
            if (!item.photo) {
              item.photo = item.face
            }
            if (!item.source) {
              item.source = item.face
            }


            if (item.profile?.indexOf('vk.com') > 0) {
              output.foundSources.push('vk')
              item.network = 'vk'
            }
            if (item.profile?.indexOf('ok.ru') > 0) {
              output.foundSources.push('ok')
              item.network = 'ok'
            }
            if (item.profile?.indexOf('tiktok.com') > 0) {
              output.foundSources.push('tiktok')
              item.network = 'tiktok'
              item.profile = item.profile?.replace('http://', 'https://')?.replace('https://www.', 'https://')
            }

            //sniper preparation
            if (!item.network) {
              item = getImageCardInfo(item)
              if (type === 'photo_searcher') {
                output.foundSources.push('sniper_api')
              } else {
                output.foundSources.push('sniper')
              }
              if (item.network === 'tiktok') {
                output.foundSources.push('tiktok')
              }
            }
            if (item?.score) {
              item.score = Math.floor(item.score * 1)
            }

            if (item?.score * 1 > 80) {
              output.identicalImages?.push(item)
            } else {
              output.similarImages?.push(item)
            }

            if (item.social_network && item.social_network === 'sniper_2' ) {
              item = getImageCardInfo(item)
              output.foundSources.push('sniper_2')
            }
            
          })
      }
    })
    sources.forEach((source: any) => {
      if (!output.foundSources.includes(source.name)) {
        output.notFoundSources.push(source.name)
      }
    })
  }

  if (['irbis_eye'].includes(type)) {
    apiDataEntities.forEach((entity: any) => {
      if (entity.data && Array.isArray(entity.data)) {
        entity.data.forEach((item: any) => {

          if (!item.photo_id || item.photo_id === -1) {
            item.photo_id = item.userid
          }

          if (item.thumbnail) {
            item.face = item.thumbnail
            //item.source = item.thumbnail
          }

          if (Array.isArray(item.details)) {
            item.source = item.details[0].url
          }

          if (item.firstname) {
            item.first_name = item.firstname
          }

          if (item.userid) {
            item.network = 'vk'
            item.user_id = item.userid
            item.profile = getLinkToProfile('vkid', item.userid)
          }

          if (item?.score) {
            item.score = Math.floor(item.score * 1)
          }

          if (item?.score * 1 > 80) {
            output.identicalImages?.push(item)
          } else {
            output.similarImages?.push(item)
          }

          output.foundSources.push('irbis_eye')
        })
      }
    })
    sources.forEach((source: any) => {
      if (!output.foundSources.includes(source.name)) {
        output.notFoundSources.push(source.name)
      }
    })
  }

  if (['post'].includes(type)) {
    sources.forEach((source: any) => {
      if (!output.foundSources.includes(source.name)) {
        output.notFoundSources.push(source.name)
      }
    })
  }

  if (['sentiment_analysys'].includes(type)) {
    apiDataEntities.forEach((entity: any) => {
      const from = entity.from?.toLowerCase()
      if (!entity?.isFailed) {
        output.foundSources.push(from)
      }
    })
  }

  if (['web_data_search', 'fb_person_wall_keyword'].includes(type)) {
    output.foundSources.push('fb')
  }

  if (['irbis_facebook', 'irbis_instagram','web_data_search', 'fb_person_wall_keyword', 'crolo_x', 'crolo_vk', 'crolo_ok', 'irbis_tiktok', 
  'bluesky_v3', 'api_v2_x', 'api_v2_instagram', 'api_v2_linkedin', 
    'ssn_trace', 'nat_crim_check', 'pep', 'court_records'].includes(type)) {
    apiDataEntities.forEach( (entity : any) => {
      if (entity.from === 'api_summary') {
        output.isApiSummary = true
      }
    } )
  }

  output.notFoundSources = output.notFoundSources.filter(nf => !output.foundSources.includes(nf))
  
  output.foundSources = Array.from(new Set(output.foundSources))
  output.notFoundSources = Array.from(new Set(output.notFoundSources))
  //remove all sources in case if only botim presented

  // if (sources.length === 1 && sources[0]?.name === 'botim') {
  //   output.foundSources = output.foundSources.filter(source => source === 'botim')
  //   output.notFoundSources = output.notFoundSources.filter(source => source === 'botim')
  // } else {
  //   output.notFoundSources = output.notFoundSources.filter(source => source !== 'botim')
  // }

  //remove name duplicates
  output.names = output.names.filter((e, i) => {
    return output.names.findIndex((x) => {
      return x.app == e.app && x.name == e.name
    }) == i
  })
  output.addresses = output.addresses.filter((e, i) => {
    return output.addresses.findIndex((x) => {
      return x.app == e.app && x.address == e.address
    }) == i
  })
  output.emails = output.emails.filter((e, i) => {
    return output.emails.findIndex((x) => {
      return x.app == e.app && x.email == e.email
    }) == i
  })

  return output
}
