import * as React from "react"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { AccordionSummary, Avatar, Link, Stack, useTheme } from '@mui/material'
import { prepareNameApiResult } from "../../../store/searcher/service"
import { NameApiDataItem, SearchResult } from "../../../store/searcher/reducer"
import sprite_ from '../../../sass/sprite.module.sass'

function createData( picture: string, source: string, name: string, id: string, info: any[]) {
  return {
    picture, source, name, id, info,
  }
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      {/* <TableRow sx={{ "& > *": { borderBottom: "unset" } }}> */}
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Avatar src={row.picture} />
        </TableCell>

        <TableCell align="center">
          <AccordionSummary>
            <Stack direction="row" spacing={1}>
              <Box
                className={sprite_['social__small_' + row.source.toLowerCase()]}
              />
            </Stack>
          </AccordionSummary>
        </TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.id}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          {
                    (row.source === 'FB' || row.source === 'VK' || row.source === 'OK') && (
                      <Box sx={{ margin: 1 }}>
                      {/* <Typography variant="h6" gutterBottom component="div">
                        Additional info:
                      </Typography> */}
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">User Profile:</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.info.map((row) => (
                            <TableRow>
                              <TableCell component="th" scope="row" align="center">
                              <Link variant="underlined" href={row.profile_url} target="_blank">
                              {"Open Profile"}
                              </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    )
            }  
            {
                    row.source === 'INSTAGRAM' && (
                      <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                          <TableCell align="center">User Profile:</TableCell>
                          <TableCell align="center">Is private account:</TableCell>
                          {/* <TableCell align="right">Is verified account:</TableCell> */}
                          <TableCell align="center">Is verified account:</TableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.info.map((row) => (
                            <TableRow>
                              <TableCell component="th" scope="row" align="center">
                              <Link variant="underlined" href={row.profile_url} target="_blank">
                              {"Open Profile"}
                              </Link>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.isPrivate === false ? "Public account" : "Private account"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.isVerified === true ? "Verified account" : "Not verified account"
                              ) 
                              }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    )
            }  
            {
                    row.source === 'LINKEDIN' && (
                      <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                          <TableCell align="center">User Profile:</TableCell>
                          <TableCell align="center">Location:</TableCell>
                          <TableCell align="center">Position:</TableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.info.map((row) => (
                            <TableRow>
                              <TableCell component="th" scope="row" align="center">
                              <Link variant="underlined" href={row.profile_url} target="_blank">
                              {"Open Profile"}
                              </Link>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.location ? row.location : "-"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.position ?  row.position : "-"
                              ) 
                              }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    )
            }
            {
                    (row.source === 'X' || row.source === 'TWITTER' )  && (
                      <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                          <TableCell align="center">User Profile:</TableCell>
                          <TableCell align="center">Is private account:</TableCell>
                          <TableCell align="center">Is verified account:</TableCell>
                          <TableCell align="center">Following:</TableCell>
                          <TableCell align="center">Followers:</TableCell>
                          <TableCell align="center">Posts:</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.info.map((row) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                              <Link variant="underlined" href={row.profile_url} target="_blank">
                              {"Open Profile"}
                              </Link>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.isPrivate === false ? "Public account" : "Private account"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.isVerified === true ? "Verified account" : "Not verified account"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.following_count ? row.following_count : "-"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.followers_count ? row.followers_count : "-"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.posts_count ? row.posts_count : "-"
                              ) 
                              }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    )
            }
            {
                    (row.source === 'TIKTOK' )  && (
                      <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                          <TableCell align="center">User Profile:</TableCell>
                          <TableCell align="center">Following:</TableCell>
                          <TableCell align="center">Followers:</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.info.map((row) => (
                            <TableRow>
                              <TableCell component="th" scope="column" align="center">
                              <Link variant="underlined" href={row.profile_url} target="_blank">
                              {"Open TikTok Profile"}
                              </Link>
                              { 
                              row.instagramProfileLink && 
                              <Link variant="underlined" href={row.instagramProfileLink} target="_blank">
                                <p>
                              {"Open Instagram Profile"}
                              </p>
                              </Link>
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.following_count ? row.following_count : "-"
                              ) 
                              }
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.followers_count ? row.followers_count : "-"
                              ) 
                              }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    )
            }
            {
                    (row.source === 'BLSK')  && (
                      <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                          <TableCell align="center">User Profile:</TableCell>
                          <TableCell align="center">Profile Description:</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.info.map((row) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                              <Link variant="underlined" href={row.profile_url} target="_blank">
                              {"Open Profile"}
                              </Link>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                              { 
                              (
                                row.description ? row.description : "-"
                              ) 
                              }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    )
            }       
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}


type Props = {
  apiData: SearchResult
}

export default function CollapsibleTable(props: Props) {
  const theme = useTheme()
  const { apiData } = props

  const data = prepareNameApiResult(apiData)
  const results = data && data.data ? data.data : null

  const rows: any[] = []
  
  results?.forEach((el: NameApiDataItem, idx) => {
    switch (el.social_network) {
      case 'FB':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url : el.profile_url}]))
      break
      case 'INSTAGRAM':
        rows.push(createData(el.additionalPhoto ? el.additionalPhoto : el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url : el.profile_url, isPrivate : el.isPrivate, isVerified : el.isVerified}]))
      break
      case 'LINKEDIN':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url: el.profile_url, location: el.location, position: el.position }]))
      break
      case 'VK':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url: el.profile_url}]))
      break
      case 'X':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url : el.profile_url, isPrivate : el.isPrivate, isVerified : el.isVerified, following_count: el.following_count, 
            followers_count: el.followers_count, posts_count: el.posts_count, created_at: el.created_at}]))
      break
      case 'TWITTER':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url : el.profile_url, isPrivate : el.isPrivate, isVerified : el.isVerified, following_count: el.following_count, 
            followers_count: el.followers_count, posts_count: el.posts_count, created_at: el.created_at}]))
      break
      case 'OK':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url : el.profile_url}]))
      break
      case 'TIKTOK':
        rows.push(createData(el.profileImageUrl, el.social_network, el.profileName, el.profile_id, 
          [{profile_url : el.profile_url, following_count: el.following_count, followers_count: el.followers_count, instagramId: el.instagramId, instagramProfileLink: el.instagramProfileLink }]))
      break
      case 'BLUESKY':
        rows.push(createData(el.profileImageUrl, 'BLSK', el.profileName, el.profile_id, 
          [{profile_url : el.profile_url, description: el.description }]))
      break
      default:
        break
    }

    
  })


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Picture</TableCell>
            <TableCell align="center">Source</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Id</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
