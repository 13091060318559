import * as React from 'react'
import Box from '@mui/material/Box'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { SearchResult } from '../../../store/searcher/reducer'
import { Avatar, Button, Card, CardContent, Link, Typography } from '@mui/material'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import { JsonCommon } from './JsonCommon'

type Props = {
  apiData: SearchResult,
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horisontal-tabpanel-${index}`}
      aria-labelledby={`horisontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default function EmailList(props: Props) {
  const outputObject = props.apiData as any
  const profiles : any = []
  if (outputObject?.apiDataEntities && Array.isArray(outputObject?.apiDataEntities)) {
    const apiDataEntities = outputObject?.apiDataEntities
    apiDataEntities.forEach( ( el : any) => {
      if (el?.data && Array.isArray(el?.data)) {
        const data = el?.data
        data.forEach( (data_el : any) => {
          if (data_el?.email) {
          profiles.push(data_el as JSON)
          }
        } )
      }
    })
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  console.log(profiles)
  return (
    <>
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 1 },
          },
        }}
      >
      {profiles
          .filter((el: any) => el?.email) // Ensure only items with email are processed
          .map((el: any, index: number) => (
            <Tab icon={<PersonPinIcon />} label={ el?.email } />
          ))}
      </Tabs>
      {profiles
          .filter((el: any) => el?.email) // Ensure only items with email are processed
          .map((profile: any, index: number) => (
            <TabPanel value={value} index={index} >
            
            <Box key={profile.email} role="tabpanel">
            
              {/* Header */}
              <Box display="flex">
                <Avatar src={profile.person?.photoUrl} alt="Profile" sx={{ width: 100, height: 100 }} />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h5">{profile.person?.firstName} {profile.person?.lastName}<br /></Typography>
                  <Typography variant="body2" color="textSecondary">{profile.person?.headline}<br /></Typography>
                  <Link href={profile.person?.linkedInUrl} target="_blank" rel="noopener noreferrer" sx={{ marginTop: 1, display: 'block' }}>
                    View Profile
                  </Link>
                </Box>
              </Box>

              {/* Details */}
              <CardContent>
                <Typography ><br /><strong>Location:</strong><br /> {profile.person?.location}</Typography>
                <Typography ><br /><strong>Summary:</strong><br /> {profile.person?.summary}</Typography>
              </CardContent>

              {/* Work Experience */}
              <CardContent>
                <Typography sx={{ marginBottom: 1 }}><br /><strong>Work Experience:</strong><br /></Typography>
                {profile.person?.positions?.positionHistory?.slice(0, 2).map((job: any, index: number) => (
                  <Box key={index} sx={{ marginBottom: 1 }}>
                    <Typography >
                      {job.title} at: <Link href={job.linkedInUrl} target="_blank" rel="noopener noreferrer" sx={{ marginTop: 1, display: 'block' }}> {job.companyName} </Link>
                    </Typography>
                  </Box>
                ))}
              </CardContent>

              {/* Education */}
              <CardContent>
                <Typography sx={{ marginBottom: 1 }}><br /><strong>Education:</strong><br /></Typography>
                {profile.person?.schools?.educationHistory?.slice(0, 2).map((edu: any, index: number) => (
                  <Typography key={index} variant="body1">
                    <Link href={edu.linkedInUrl} target="_blank" rel="noopener noreferrer" sx={{ marginTop: 1, display: 'block' }}>{edu.schoolName}</Link>
                    <br />
                  </Typography>
                ))}
              </CardContent>

              {/* Company */}
              <CardContent>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>Company:</Typography>
                <Box display="flex" alignItems="center">
                  <Avatar src={profile.company?.logo} alt="Company Logo" sx={{ width: 40, height: 40 }} />
                  <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1">{profile.company?.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{profile.company?.industry}</Typography>
                    <Link href={profile.company?.linkedInUrl} target="_blank" rel="noopener noreferrer" sx={{ marginTop: 1, display: 'block' }}>
                      View Company
                    </Link>
                  </Box>
                </Box>
              </CardContent>
            
          </Box>

          </TabPanel>
          ))}

      
    </Box>
    <JsonCommon apiData={props.apiData} />
    </>
  )
  
}