export const isIrbisLite = String(process.env.REACT_APP_IRBIS_LITE).trim() === "true"
export const currencyFullName = isIrbisLite ? 'USD' : 'EUR'
export const currencySymbol = isIrbisLite ?  '$' : '€'
export const priceColor = isIrbisLite ? '#3c64c3' : '#3898D3'
export const nameCaption = isIrbisLite ? 'CLEARCHECK' : 'IRBIS'
export const domainsWebColl = isIrbisLite ? 'https://clearcheck.io/' : 'https://irbis.espysys.com'
export const domainsKYC = isIrbisLite ? 'clearcheck.io' : 'irbis.espysys.com' 
export const apiDocsUrl = isIrbisLite ? 'https://api-docs.clearcheck.io/' : 'https://api-docs.espysys.com/' 

export const menuDashIconPrimaryColor = isIrbisLite ? '#facc00' : 'dimgrey'
export const menuDashIconActivePrimaryColor = isIrbisLite ? '#facc00' : 'white'

export const menuSearchIconPrimaryColor = isIrbisLite ? '#facc00' : 'lightgrey'
export const menuSearchIconSecondaryColor = isIrbisLite ? '#facc00' : 'dimgrey'
export const menuSearchIconActivePrimaryColor = isIrbisLite ? '#facc00' : 'white'

export const menuPackagesIconPrimaryColor = isIrbisLite ? '#facc00' : 'lightgrey'
export const menuPackagesIconSecondaryColor = isIrbisLite ? '#facc00' : 'dimgrey'
export const menuPackagesIconActivePrimaryColor = isIrbisLite ? '#facc00' : 'white'

export const menuHelpIconPrimaryColor = isIrbisLite ? '#facc00' : 'lightgrey'
export const menuHelpIconSecondaryColor = isIrbisLite ? '#facc00' : 'dimgrey'
export const menuHelpIconActivePrimaryColor = isIrbisLite ? '#facc00' : 'white'

export const changedColorText = isIrbisLite ? '#3c64c3' : '#01163E'
export const changedColorPrimary = isIrbisLite ? '#3c64c3' : '#2E97D5'

export const muiDividerRoot = isIrbisLite ? '#facc00' : 'rgba(46,151,213,0.5)'
export const muiTypographyrRoot = isIrbisLite ? '#facc00' : '#546b7e'
export const muiTypographyrRootSelected = isIrbisLite ? '#facc00' : '#FFF'

export const packageColor = isIrbisLite ? '#3c64c3' : '#3898D3'


