import React from 'react'
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  
  Divider,

} from '@mui/material'
import { useAccordionStyles } from '../../../style/lookup'

import { ResultTitle } from '../../../style/result'


type Props = {
  apiData: any
}

export const JsonCommon = (props: Props) => {
  const { apiData } = props
  const accordionClasses: any = useAccordionStyles()
  const allData = apiData?.apiDataEntities
  const htmlData: any = allData.find((item: any) => item.from === "api_summary")?.data
  const data: any = allData.find((item: any) => item.from !== "api_summary")?.data


  const [accordionOpen, setAccordionOpen] = React.useState<boolean>(true)

  const handleChangeAccordion =
    (_: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen(expanded)
    }

  return (
    <>
      {
        htmlData && (
        <Stack sx={{ backgroundColor: '#F0F2FA', padding: "10px 20px"}}>
          <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        </Stack>
        )
      }
      <Accordion
        classes={accordionClasses}
        square
        expanded={accordionOpen}
        onChange={handleChangeAccordion}
        sx={{ marginTop: '10px' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack>
          <ResultTitle sx={{ marginTop: '10px' }}>API results:</ResultTitle>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Divider
            sx={{
              margin: '-5px 0px 15px 0px',
              border: `0.1px solid rgba(46, 151, 213, 0.3)`,
            }}
          />
          <Stack sx={{ backgroundColor: '#F0F2FA', overflowX: 'auto' }}>
            <pre>{JSON.stringify(data, null, ' ')}</pre>
          </Stack>
        </AccordionDetails>
      </Accordion>
      
      </>
  )
}