import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem, ListItemButton,
  ListItemIcon,
  ListItemText, Stack,
} from '@mui/material'
import {
  useDrawerStyles,
  useListItemStyles,
  useTriggerStyles,
} from '../../style/common'
import {
  Home as HomeIcon,
  Bag2 as PackagesIcon,
  Password as ApikeyIcon,
  Search as SearchIcon,
  InfoSquare as HelpIcon,
  Setting as SettingIcon,
  Scan,
  ChevronUpCircle as UpIcon,
  ChevronDownCircle as DownIcon,
} from 'react-iconly'
import { MenuOpen as MenuIcon } from '@mui/icons-material'
import { useDispatch, useSelector } from '../../store'
import Collapse from '@mui/material/Collapse'
import LogoutIcon from '../../asset/icon/logout.png'
import { signOut } from '../../store/auth/actionCreator'
import { isIrbisLite, menuDashIconActivePrimaryColor, menuDashIconPrimaryColor, menuHelpIconActivePrimaryColor, menuHelpIconPrimaryColor, menuHelpIconSecondaryColor, menuPackagesIconActivePrimaryColor, menuPackagesIconPrimaryColor, menuPackagesIconSecondaryColor, menuSearchIconActivePrimaryColor, menuSearchIconPrimaryColor, menuSearchIconSecondaryColor } from '../../function/irbis-lite-config'

type Props = {
  navbarOpen: boolean
  setNavbarOpen: any,
  isMobile: boolean | undefined,
}

export function Menu({ navbarOpen, setNavbarOpen, isMobile }: Props) {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const drawerClasses = useDrawerStyles()
  const listItemClasses = useListItemStyles()
  const triggerClasses = useTriggerStyles()

  const { isRtl } = useSelector((state) => state.common)
  const user = useSelector((state) => state.settings)

  const data1 = [
    {
      label: t('menu.dashboard'),
      icon: <HomeIcon set="bold" primaryColor={menuDashIconPrimaryColor} size={25} />,
      iconActive: <HomeIcon set="bold" primaryColor={menuDashIconActivePrimaryColor} size={25} />,
      url: '/dashboard',
      mobileHidden: true,
    },
    {
      label: t('menu.search'),
      icon: (
        <SearchIcon
          size={25}
          set="bulk"
          primaryColor={menuSearchIconPrimaryColor}
          secondaryColor={menuSearchIconSecondaryColor}
        />
      ),
      iconActive: <SearchIcon size={25} set="bold" primaryColor={menuSearchIconActivePrimaryColor} />,
      url: '/',
    },
    {
      label: t('menu.packages'),
      icon: (
        <PackagesIcon
          size={25}
          set="bulk"
          primaryColor={menuPackagesIconPrimaryColor}
          secondaryColor={menuPackagesIconSecondaryColor}
        />
      ),
      iconActive: <PackagesIcon size={25} set="bold" primaryColor={menuPackagesIconActivePrimaryColor} />,
      url: '/packages',
    },
    {
      label: t('menu.developer'),
      icon: (
        <div className="menu__devIcon">
          <b className="menu__devIcon__inner">&lt;/&gt;</b>
        </div>
      ),
      iconActive: (
        <div className="menu__devIcon_active">
          <b className="menu__devIcon__inner_active">&lt;/&gt;</b>
        </div>
      ),
      url: '/developer',
      mobileHidden: true,
    },
    !isIrbisLite && {
      label: t('menu.webint'),
      icon: (
        <Scan
          size={25}
          set="bulk"
          primaryColor="lightgrey"
          secondaryColor="dimgrey"
        />
      ),
      iconActive: <Scan size={25} set="bold" primaryColor="white" />,
      url: '/irbispro',
      disabled: false,
      mobileHidden: true,
    },
    
  ].filter(Boolean)

  const data2 = [
    !isIrbisLite && {
      label: t('menu.webint_about'),
      icon: (
        <HelpIcon
          size={25}
          set="bulk"
          primaryColor={menuHelpIconPrimaryColor}
          secondaryColor={menuHelpIconSecondaryColor}
        />
      ),
      iconActive: <HelpIcon size={25} set="bold" primaryColor={menuHelpIconActivePrimaryColor} />,
      url: '/irbispro/about',
      disabled: false,
      mobileHidden: true,
    },
    {
      label: t('menu.support'),
      icon: (
        <HelpIcon
          size={25}
          set="bulk"
          primaryColor="lightgrey"
          secondaryColor="dimgrey"
        />
      ),
      iconActive: <HelpIcon size={25} set="bold" primaryColor="white" />,
      url: '/support',
    },
  ].filter(Boolean)

  let navbarClassName = navbarOpen ? 'active' : ''
  navbarClassName = `${navbarClassName} ${isRtl ? 'rtl' : 'ltr'}`

  let logoClassName = navbarOpen ? 'menu__logoIrbis' : 'menu__logo_inactive'
  if (isIrbisLite) {
    logoClassName = navbarOpen ? 'menu__logoClearcheck' : 'menu__logo_inactive'
  }

  const triggerClassName = isRtl ? 'rtl' : 'ltr'
  const listItemClassname = isRtl ? 'rtl' : ''

  const [submenu, setSubmenu] = React.useState({open: false})

  const handleMenuClick = (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation()
    setSubmenu((state) => ({ ...state, open: !state.open }))
  }

  function handleSignOutClick() {
    dispatch(signOut)
  }

  return (
    <>
      {
        isMobile
          ? <Drawer
            classes={drawerClasses}
            className={navbarClassName}
            anchor={isRtl ? 'right' : 'left'}
            onClose={() => setNavbarOpen(false)}
            open={navbarOpen}
          >
            <Grid
              className="menu"
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <div onClick={() => history.push('/')} className={logoClassName} />
              </Grid>
              <Grid item>
                <IconButton
                  className={triggerClassName}
                  classes={triggerClasses}
                  onClick={() => setNavbarOpen(false)}
                >
                  <MenuIcon style={{ transform: navbarOpen ? '' : 'scaleX(-1)' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />

            <List>
              {data1.map((el: any) => !el?.mobileHidden && (
                <ListItem
                  key={el.label}
                  className={listItemClassname}
                  classes={listItemClasses}
                  onClick={() => history.push(el.url)}
                  selected={location.pathname === el.url}
                  button
                >
                  <ListItemIcon>
                    {location.pathname === el.url ? el.iconActive : el.icon}
                  </ListItemIcon>
                  <ListItemText primary={el.label} />
                </ListItem>
              ))}
            </List>

            <Divider />

            <List>
              {data2.map((el : any) => !el?.mobileHidden && (
                <ListItem
                  key={el.label}
                  className={listItemClassname}
                  classes={listItemClasses}
                  onClick={() => history.push(el.url)}
                  selected={location.pathname === el.url}
                  button
                >
                  <ListItemIcon>
                    {location.pathname === el.url ? el.iconActive : el.icon}
                  </ListItemIcon>
                  <ListItemText primary={el.label} />
                </ListItem>
              ))}
            </List>
            {
              user.firstname && <List>
                <ListItem
                  className={listItemClassname}
                  classes={listItemClasses}
                  onClick={() => history.push('/settings')}
                  selected={location.pathname == '/settings'}
                  button
                >
                  <ListItemIcon>
                    <Avatar src={user?.avatar || ''} sx={{ width: 24, height: 24 }} />
                  </ListItemIcon>
                  <ListItemText primary={`${user.firstname} ${user.lastname}`} />
                </ListItem>

                <ListItem
                  className={listItemClassname}
                  classes={listItemClasses}
                  onClick={handleSignOutClick}
                  button
                  //selected={submenu.url === ''}
                >
                  <ListItemIcon><img src={LogoutIcon} style={{width: '25px', height: '25px'}} /></ListItemIcon>
                  <ListItemText primary={t('header.logout')} />
                </ListItem>

              </List>
            }

          </Drawer>
          : <Drawer
            classes={drawerClasses}
            className={navbarClassName}
            anchor={isRtl ? 'right' : 'left'}
            onClose={() => setNavbarOpen(false)}
            variant="permanent"
          >
            <Grid
              className="menu"
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <div onClick={() => history.push('/')} className={logoClassName} />
              </Grid>
              <Grid item>
                <IconButton
                  className={triggerClassName}
                  classes={triggerClasses}
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <MenuIcon style={{ transform: navbarOpen ? '' : 'scaleX(-1)' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />

            <Stack direction={'column'} sx={{height: '100%', justifyContent: 'space-between'}}>
              <Stack direction={'column'}>
                <List>
                  {
                    data1.map((el : any) =>
                      <ListItem
                        key={el.label}
                        className={listItemClassname}
                        classes={listItemClasses}
                        onClick={() => history.push(el.url)}
                        selected={location.pathname === el.url}
                        button
                        disabled={el?.disabled}
                      >
                        <ListItemIcon>
                          {location.pathname === el.url ? el.iconActive : el.icon}
                        </ListItemIcon>
                        <ListItemText primary={el.label} />
                      </ListItem>,
                    )
                  }
                </List>

                <Divider />

                <List>
                  {data2.map((el : any) => (
                    <ListItem
                      key={el.label}
                      className={listItemClassname}
                      classes={listItemClasses}
                      onClick={() => history.push(el.url)}
                      selected={location.pathname === el.url}
                      button
                    >
                      <ListItemIcon>
                        {location.pathname === el.url ? el.iconActive : el.icon}
                      </ListItemIcon>
                      <ListItemText primary={el.label} />
                    </ListItem>
                  ))}
                </List>

                <Divider />

                {
                  user.firstname && <List>
                    <ListItem
                      className={listItemClassname}
                      classes={listItemClasses}
                      onClick={() => history.push('/settings')}
                      selected={location.pathname == '/settings'}
                      button
                    >
                      <ListItemIcon>
                        <Avatar src={user?.avatar || ''} sx={{ width: 24, height: 24 }} />
                      </ListItemIcon>
                      <ListItemText primary={`${user.firstname} ${user.lastname}`} />
                    </ListItem>

                  </List>
                }
              </Stack>

              <Stack direction={'column'}>
                {
                  user.firstname && <List>
                    <ListItem
                      className={listItemClassname}
                      classes={listItemClasses}
                      onClick={handleSignOutClick}
                      button
                      //selected={submenu.url === ''}
                    >
                      <ListItemIcon><img src={LogoutIcon} style={{width: '25px', height: '25px'}} /></ListItemIcon>
                      <ListItemText primary={t('header.logout')} />
                    </ListItem>
                  </List>
                }
              </Stack>
            </Stack>

          </Drawer>
      }

    </>

  )
}
