import { ILookup, SearchResult, Source } from './reducer'
import { SearcherAction } from './actionType'
import { ApiDataSummarizing, Name, VerifiedNames } from './service'

export const fetchLookup: SearcherAction = { type: 'searcher/GET_LOOKUP' }

export const setLookup = (data: object[]): SearcherAction => ({
  type: 'searcher/SET_LOOKUP',
  data,
})

export const startLookup = (
  data: ILookup,
  criteria: string,
  percentage: string | undefined,
  tableLimit: number,
  lookupType: Source | undefined = undefined,
  country: string | undefined = undefined,
  inputIp: string | undefined = undefined,
): SearcherAction => ({
  type: 'searcher/START_LOOKUP',
  data,
  criteria,
  percentage,
  tableLimit,
  lookupType,
  country,
  inputIp,
})

export const startVerify = (
  data: VerifiedNames,
  tableLimit: number,
): SearcherAction => ({
  type: 'searcher/START_VERIFY',
  data,
  tableLimit,
})

export const startSummarizing = (
  data: ApiDataSummarizing,
  tableLimit: number,
): SearcherAction => ({
  type: 'searcher/START_SUMMARIZING',
  data,
  tableLimit,
})

export const fetchResultById = (id: string): SearcherAction => ({
  type: 'searcher/FETCH_RESULT_BY_ID',
  id,
})

export const fetchResultByIdAsync = (
  id: string,
  callback: (data: SearchResult) => void,
): SearcherAction => ({
  type: 'searcher/FETCH_RESULT_BY_IDASYNC',
  id,
  callback,
})
export const fetchNotificationResult = (
  requestMonitorId: number,
): SearcherAction => ({
  type: 'searcher/FETCH_NOTIFICATION_RESULT',
  requestMonitorId,
})

export const toggleViewResult = (
  data: SearchResult | null,
): SearcherAction => ({
  type: 'searcher/TOGGLE_VIEW_RESULT',
  data,
})

export const deleteResults = (
  ids: number[],
  callback: () => void,
): SearcherAction => ({
  type: 'searcher/DELETE_RESULTS',
  ids,
  callback,
})

export const setPage = (data: number) => ({
  type: 'searcher/SET_PAGE',
  data: data,
})

export const getPDFData = (
  requestMonitorId: number,
  apiKey: string,
): SearcherAction => ({
  type: 'searcher/GET_PDF_DATA',
  requestMonitorId,
  apiKey,
})

export const getPDF = (requestMonitorId: number): SearcherAction => ({
  type: 'searcher/GET_PDF',
  requestMonitorId,
})

export const getLinkPreview = (link: string, callback: (ogData: any) => void): SearcherAction => ({
  type: 'searcher/GET_LINK_PREVIEW',
  link,
  callback,
})