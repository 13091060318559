import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { createTheme, Divider } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { nameCaption } from '../../function/irbis-lite-config'

export function WelcomePackageRule() {
    const [open, setOpen] = React.useState(true)
    const theme = createTheme({})
    const history = useHistory()

    function handleRedirectCustomOnly() {
        history.push({ pathname: '/packages/general' })
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                maxWidth="md" // Increase the maxWidth to 'md' (medium) or any other value as needed
                fullWidth
                sx={{
                    '& .MuiDialogContent-root': {
                        padding: theme.spacing(2),
                    },
                    '& .MuiDialogActions-root': {
                        padding: theme.spacing(1),
                    },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Welcome package info
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography gutterBottom>
                        Dear Client, you are using a Welcome package which doesn't give
                        you an option to make searches in parallel. To make searches without limitation, please,
                        <Link to={{ pathname: '/packages/general' }}>chose another package</Link>. Thank you for using {nameCaption}.
                    </Typography>
                    <Divider variant="popup" color="primary" />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleRedirectCustomOnly}>
                        Packages
                    </Button>
                </DialogActions>


            </Dialog>
        </React.Fragment>
    )
}
