import { createTheme } from '@mui/material/styles'
import { changedColorPrimary, changedColorText } from './function/irbis-lite-config'

export const colorPrimary = changedColorPrimary
export const colorPrimaryLight = '#F2FBFF'
export const colorPrimaryDark = '#3082B3'
export const colorSecondary = '#979797'
export const colorSecondaryLight = '#E8E8E8'
export const colorSecondaryDark = '#8B929B'
export const colorSuccess = '#2ED571'
export const colorSuccessLight = '#C5F2C7'
export const colorError = '#FF0000'
export const colorErrorLight = '#FCD0CF'
export const colorErrorDark = '#CB281E'
export const colorText = '#01163E'
export const colorLightGreen = '#D1EC2C'
export const colorLightGreenLight = '#E3FD4F'
export const colorBackground = '#F0F2FA'
export const colorGreyedOut = '#D7D7D7'

export const fontLight = 'Montserrat-Light, sans-serif'
export const fontRegular = 'Montserrat-Regular, sans-serif'
export const fontSemiBold = 'Montserrat-SemiBold, sans-serif'
export const fontBold = 'Montserrat-Bold, sans-serif'
export const fontBlack = 'Montserrat-Black, sans-serif'

export const boxShadow = '2px 2px 7px rgba(0, 0, 0, 0.1)'

const theme = createTheme({})
export const customTheme = createTheme({
  typography: {
    fontFamily: `"Montserrat-Regular", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  zIndex: {
    drawer: 1,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: colorBackground,
          minWidth: 340,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: fontRegular,
          color: colorText,
        },
        body1: {
          display: 'inline',
          fontSize: 13,
        },
        body2: {
          display: 'inline',
          fontSize: 13,
          fontFamily: fontSemiBold,
          color: colorPrimary,
        },
      },
      variants: [
        {
          props: { variant: 'light' },
          style: {
            fontFamily: fontLight,
          },
        }, // light
        {
          props: { variant: 'semiBold' },
          style: {
            fontFamily: fontSemiBold,
          },
        }, // semiBold 600
        {
          props: { variant: 'bold' },
          style: {
            fontFamily: fontBold,
          },
        }, // bold 700
        {
          props: { variant: 'title' },
          style: {
            fontSize: 26,
            fontFamily: fontBold,
            [theme.breakpoints.only("xs")]: {
              fontSize: 18,
            },
          },
        }, // title (content)
        {
          props: { variant: 'text' },
          style: {
            fontSize: 16,
            fontFamily: fontLight,
            [theme.breakpoints.only("xs")]: {
              fontSize: 14,
            },
          },
        }, // text (content)
        {
          props: { variant: 'underlined' },
          style: {
            textDecoration: 'underline !important',
          },
        }, // underlined
      ],
    },
    MuiBadge: {
      styleOverrides: {
        anchorOriginTopRightRectangular: {
          top: 7,
          right: 7,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          whiteSpace: 'nowrap',
          fontFamily: fontSemiBold,
        },
        contained: {
          color: '#FFF',
        },
        sizeSmall: {
          padding: '0 25px',
          height: 27,
        },
        sizeMedium: {
          padding: '0 40px',
          height: 39,
          fontSize: 15,
        },
        sizeLarge: {
          padding: '8px 34px',
          height: 44,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'containedRounded', color: 'success' },
          style: {
            color: '#FFF',
            background: colorSuccess,
            borderRadius: 21,
            '&:hover': {
              background: colorSuccessLight,
            },
          },
        }, // containedRounded success
        {
          props: { variant: 'outlinedRounded', color: 'primary' },
          style: {
            color: colorPrimary,
            borderColor: colorPrimary,
          },
        }, // outlinedRounded primary
        {
          props: { variant: 'outlinedRounded' },
          style: {
            borderRadius: 21,
            border: '1px solid',
            borderColor: colorSecondary,
          },
        }, // outlinedRounded
        {
          props: { variant: 'underlined' },
          style: {
            padding: 0,
            height: 20,
            textDecoration: 'underline',
            fontSize: 14,
            fontFamily: fontSemiBold,
            color: colorPrimary,
            '&:hover': {
              color: colorPrimaryDark,
              background: 'none',
            },
          },
        }, // underlined
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          padding: '17px 30px',
          width: '100%',
          height: '100%',
          borderRadius: 20,
          boxSizing: 'border-box',
          boxShadow: boxShadow,
          [theme.breakpoints.only("xs")]: {
            padding: '15px',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        title: {
          fontSize: 18,
          fontFamily: fontSemiBold,
          color: colorText,
          [theme.breakpoints.only("xs")]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          marginTop: 3,
          padding: 0,
          [theme.breakpoints.only("xs")]: {
            fontSize: 12,
            lineHeight: '12px',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
          padding: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: 15,
          height: 29,
          fontFamily: fontSemiBold,
        },
        sizeSmall: {
          padding: 5,
        },
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            color: '#FFF',
            background: colorSuccess,
          },
        },
        {
          props: { color: 'yellowGreen' },
          style: {
            color: '#FFF',
            background: colorLightGreen,
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 24,
          borderRadius: 20,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          textAlign: 'center',
          fontSize: 26,
          fontFamily: fontBold,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            background: colorPrimary,
          },
        },
        {
          props: { variant: 'popup' },
          style: {
            margin: '20px 0',
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '35px',
          fontFamily: fontSemiBold,
          color: '#000',
        },
        asterisk: {
          color: colorError,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& button': {
            color: '#C9C9C9',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          fontSize: 16,
          height: 42,
          background: '#EFF0F6',
          borderRadius: 5,
          '&.Mui-error': {
            background: '#FFF5F5',
            '& input::placeholder': {
              color: colorError,
              opacity: 1,
            },
            '& em': {
              color: colorError,
            },
          },
        },
        input: {
          padding: 10,
          '&::placeholder': {
            fontSize: 14,
            fontFamily: fontLight,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '& em': {
            fontSize: 14,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          margin: '8px 0',
          height: 10,
          borderRadius: 4,
          background: '#C4C4C4',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
            color: colorPrimary,
          },
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'large' },
          style: {
            fontSize: 36,
          },
        },
      ],
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          ">.MuiGrid-item": {
            [theme.breakpoints.only("xs")]: {
              //paddingTop: 12,
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colorPrimary,
      light: colorPrimaryLight,
    },
    secondary: {
      main: colorSecondary,
      light: colorSecondaryLight,
      dark: colorSecondaryDark,
    },
    success: {
      main: colorSuccess,
      light: colorSuccessLight,
    },
    error: {
      main: colorError,
      light: colorErrorLight,
      dark: colorErrorDark,
    },
    warning: {
      main: '#FFBB13',
    },
    black: {
      main: changedColorText,
    },
    yellowGreen: {
      main: colorLightGreen,
      light: colorLightGreenLight,
    },
  },
})
